import { Plus, TagIcon } from "lucide-react";

import { Button } from "@ui/components/ui/button";

export const ExternalTags = ({
  tags,
  headless = false,
}: { tags: { name: string; color: string }[]; headless?: boolean }) => {
  return (
    <>
      <div className="space-y-3">
        {!headless && (
          <div className="ml-2 text-xs font-medium text-slate-400">Tags</div>
        )}
        <div className="flex flex-wrap gap-[6px] gap-y-2">
          {tags.map((tag, i) => (
            // biome-ignore lint/suspicious/noArrayIndexKey: won't change
            <Tag key={i} name={tag.name} color={tag.color} />
          ))}
          {tags.length ? (
            <Button
              variant="ghost"
              size="sm"
              className="h-max rounded-full p-1 text-slate-300"
            >
              <Plus className="h-4 w-4" />
            </Button>
          ) : (
            <Button
              size="sm"
              variant="ghost"
              className="h-7 px-2 text-slate-400"
            >
              <TagIcon className="mr-2 h-4 w-4" />
              Add a tag
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

interface TagProps {
  name: string;
  color: string;
}

export const Tag: React.FC<TagProps> = ({ name, color }) => {
  return (
    <div className="flex w-max items-center gap-[6px] overflow-hidden rounded-full border border-slate-700 p-[3px] px-2 transition-colors hover:border-slate-600">
      <div
        className="h-[10px] w-[10px] min-w-[10px] rounded-full"
        style={{ backgroundColor: color }}
      />
      <div className="overflow-hidden overflow-ellipsis whitespace-nowrap text-xs font-semibold">
        {name}
      </div>
    </div>
  );
};
