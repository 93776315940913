"use client";

import { cn } from "@ui/lib/utils";
import { useEffect, useRef, useState } from "react";
import useBreakpoint from "use-breakpoint";

const dashedLine = (
  <line
    x1="0.5"
    y1="0"
    x2="0.5"
    y2="100%"
    strokeLinecap="round"
    strokeDasharray="8 8"
    stroke="#334155"
  />
);

export const Gridlines = () => {
  // Get the same scale calculation as AppViewport
  const containerRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState(1);
  const [visible, setVisible] = useState(false);
  const { breakpoint } = useBreakpoint({ minimized: 0, full: 550 });

  useEffect(() => {
    const updateScale = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth + 128;
        const newScale = Math.min(containerWidth / 1280, 1);
        setScale(newScale);
      }
    };

    updateScale();
    setVisible(true);
    window.addEventListener("resize", updateScale);
    return () => window.removeEventListener("resize", updateScale);
  }, []);

  // Calculate scaled positions
  const leftPosition = (px: number) => `${px * scale}px`;
  const rightPosition = (px: number) => `${px * scale}px`;

  return (
    <div
      ref={containerRef}
      className={cn(
        "absolute top-0 left-0 w-full h-full pointer-events-none transition-[opacity,transform] duration-1000 opacity-0 -translate-y-24",
        visible && "opacity-100 translate-y-0",
      )}
    >
      <div
        className={cn(
          "absolute left-4 h-[calc(100%-32px)] w-px bg-borderLight",
          breakpoint === "minimized" && "left-8",
        )}
      />
      <div className="absolute right-4 h-[calc(100%-32px)] w-px bg-borderLight hidden md:block" />
      {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
      <svg
        className="absolute h-[calc(100%-32px)] w-px hidden md:block"
        style={{ left: leftPosition(174) }}
      >
        {dashedLine}
      </svg>
      {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
      <svg
        className="absolute h-[calc(100%-32px)] w-px hidden md:block"
        style={{ right: rightPosition(174) }}
      >
        {dashedLine}
      </svg>
      <div
        className="absolute h-[calc(100%-32px)] w-px bg-borderLight hidden md:block"
        style={{ left: leftPosition(251) }}
      />
      <div
        className="absolute h-[calc(100%-32px)] w-px bg-borderLight hidden md:block"
        style={{ right: rightPosition(251) }}
      />
      <div className="absolute top-[596px] w-screen left-1/2 -translate-x-1/2 h-px bg-borderLight" />
    </div>
  );
};
