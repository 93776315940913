"use client";

import { Button } from "@ui/components/ui/button";
import Link from "next/link";
import { usePostHog } from "posthog-js/react";
import { bookingUrl } from "../common/urls";
import { easeOutQuart } from "../layouts/hero-layout";

export const Cta = () => {
  const posthog = usePostHog();

  return (
    <div
      className="mt-10 flex gap-4"
      style={{
        animation: `cta 0.75s ${easeOutQuart} 0.3s normal backwards`,
      }}
    >
      <Button
        className="rounded-xl px-4 text-base"
        size="lg"
        asChild
        onClick={() =>
          posthog.capture("primary_cta_clicked", { location: "hero" })
        }
      >
        <Link href="/auth/login">Get started</Link>
      </Button>
      <Button
        className="rounded-xl px-4 pr-3 text-base hover:bg-slate-800/50"
        size="lg"
        variant="ghost"
        asChild
        onClick={() =>
          posthog.capture("book_demo_clicked", { location: "hero" })
        }
      >
        <Link href={bookingUrl} target="_blank">
          Book a demo
        </Link>
      </Button>
    </div>
  );
};
