import { BACKGROUND_HSL, gradient } from "@/lib/gradient";
import { Button } from "@ui/components/ui/button";
import { Loader, PauseIcon } from "lucide-react";

export const CampaignStatus = () => {
  return (
    <div className="flex items-center gap-[2px]">
      <Button
        size="sm"
        variant="outline"
        className="w-max gap-1.5 px-2 h-7 text-green-200 rounded-r-none"
      >
        <Loading />
        Running
      </Button>
      <Pause />
    </div>
  );
};

const Pause = () => {
  return (
    <Button
      size="sm"
      variant="outline"
      className="w-max gap-1.5 px-2 h-7 rounded-l-none"
    >
      <PauseIcon className="w-3 h-3" />
    </Button>
  );
};

const Loading = () => {
  return (
    <div
      style={{
        maskImage: gradient(BACKGROUND_HSL, "bottom"),
        height: 12,
      }}
    >
      <Loader className="w-4 h-4 stroke-green-300 animate-spin" />
    </div>
  );
};
