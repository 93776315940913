"use client";

import { Button } from "@ui/components/ui/button";
import { cn } from "@ui/lib/utils";
import { ListFilterIcon } from "lucide-react";
import { useContext } from "react";
import { LeadViewportContext } from "../lead-viewport-context";

export const FilterCount = () => {
  const { disableBlur } = useContext(LeadViewportContext);

  return (
    <Button
      variant="ghost"
      size="sm"
      className={cn(
        "flex w-max items-center rounded-lg bg-slate-800/50 text-xs text-slate-200 overflow-hidden relative pl-[10px]",
        !disableBlur && "backdrop-blur-sm",
      )}
    >
      <ListFilterIcon
        className="h-4 w-4"
        style={{
          maskImage: "radial-gradient(circle, transparent 4px, black 4.05px)",
          maskPosition: "calc(100% + 5px) calc(100% - 3px)",
          WebkitMaskPosition: "calc(100% + 5px) calc(100% - 3px)",
        }}
      />
      <div className="w-[15px] h-[15px] flex items-center justify-center absolute right-1 top-1">
        <span className="text-[10px] font-semibold">2</span>
      </div>
    </Button>
  );
};
