"use client";

import { LeadSearch } from "@/modules/landing/leads/viewport/lead-search";
import { LeadSearchBar } from "@/modules/landing/leads/viewport/lead-search-bar";
import { LeadViewportContext } from "@/modules/landing/leads/viewport/lead-viewport-context";
import { Lists } from "@/modules/landing/leads/viewport/lists";
import { useBreakpoint } from "use-breakpoint";

export const LeadsView = () => {
  const { breakpoint } = useBreakpoint({ minimized: 0, full: 550 });

  return (
    <LeadViewportContext.Provider value={{ disableBlur: true }}>
      <div className="flex size-full">
        <Lists animate />
        <div className="flex flex-col relative w-full">
          <LeadSearch fullOpacity mobile={breakpoint === "minimized"} />
          <div className="absolute left-0 top-0 size-full z-20">
            <LeadSearchBar mobile={breakpoint === "minimized"} />
          </div>
        </div>
      </div>
    </LeadViewportContext.Provider>
  );
};
