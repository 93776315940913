import { Button } from "@ui/components/ui/button";
import { EllipsisButton } from "./ellipsis-button";

export const AdvancedFilter = ({ count }: { count: number }) => {
  return (
    <>
      <Button
        className="p-2 px-[10px] rounded-none text-xs focus-visible:ring-0 border-r"
        size="sm"
        variant="ghost"
      >
        Advanced filter{" "}
        <div className="w-4 h-4 rounded-sm border-[0.5px] border-slate-700 flex items-center justify-center ml-2 bg-slate-700/30">
          <span className="text-slate-300 text-[10px] tabular-nums">
            {count}
          </span>
        </div>
      </Button>
      <EllipsisButton />
    </>
  );
};
