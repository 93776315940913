"use client";

import { cn } from "@ui/lib/utils";
import React from "react";
import { useBreakpoint } from "use-breakpoint";
import { ViewportNavbar } from "./navbar";

export const ViewportLayout = ({
  children,
  containerClassName,
  containerStyle,
  selectedNavItem = "threads",
  viewportWrapper,
  is3d = false,
  alwaysShowNavbar = false,
}: {
  children: React.ReactNode;
  containerClassName?: string;
  containerStyle?: React.CSSProperties;
  selectedNavItem?: string;
  viewportWrapper?: React.ComponentType<{ children: React.ReactNode }>;
  alwaysShowNavbar?: boolean;
  is3d?: boolean;
}) => {
  const ViewportWrapper = viewportWrapper ?? React.Fragment;
  const { breakpoint } = useBreakpoint({ minimized: 0, full: 550 });

  return (
    <div className="flex h-full">
      {(breakpoint !== "minimized" || alwaysShowNavbar) && (
        <ViewportNavbar selectedNavItem={selectedNavItem} />
      )}
      <div
        className={cn(
          "min-w-0 flex-1 p-2 pl-0",
          breakpoint === "minimized" && "p-0",
        )}
        style={
          is3d
            ? {
                transformStyle: "preserve-3d",
                perspective: "2000px",
              }
            : undefined
        }
      >
        <ViewportWrapper>
          <div
            className={cn(
              "h-full w-full min-w-0 overflow-hidden rounded-lg border-[0.5px] bg-slate-900/40 shadow-xl",
              containerClassName,
            )}
            style={containerStyle}
          >
            <div className="flex h-full w-full flex-col">
              <div className="flex h-full flex-1 overflow-hidden relative">
                {children}
              </div>
            </div>
          </div>
        </ViewportWrapper>
      </div>
    </div>
  );
};
