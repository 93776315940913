import { ExpandableArrow } from "../components/expandable-arrow";

export const BentoContainer = ({
  children,
  heading,
  description,
  clickable = false,
}: {
  children: React.ReactNode;
  heading: string;
  description: string;
  clickable?: boolean;
}) => {
  return (
    <div className="flex flex-col gap-2 relative">
      <div className="flex flex-col gap-2 max-w-md">
        <div className="flex items-center gap-2">
          <h3 className="text-xl font-medium text-slate-200">{heading}</h3>
          {clickable && <ExpandableArrow />}
        </div>
        <p className="text-slate-400 text-lg">{description}</p>
      </div>
      {children}
    </div>
  );
};
