import { Input } from "@ui/components/ui/input";
import { CampaignLists } from "./campaign-lists";
import { CampaignStats } from "./campaign-stats";
import { CampaignStatus } from "./campaign-status";

export const CampaignProperties = () => {
  return (
    <div className="flex flex-col gap-6 mb-5">
      <div className="flex flex-col gap-1 mt-6">
        <CampaignStatus />
        <Input
          className="text-2xl font-semibold mt-[10px] border-none outline-none focus-visible:ring-0 p-0 rounded-none"
          value="NYC Ecom Outreach"
          readOnly
        />
        <CampaignLists />
        <div className="flex w-full flex-wrap gap-[6px] gap-y-2 text-slate-300 mt-3">
          <textarea
            rows={1}
            placeholder="Add a description..."
            value=""
            className="min-h-[20px] w-full resize-none overflow-hidden bg-transparent text-sm placeholder-slate-500 outline-none"
            readOnly
          />
        </div>
      </div>
      <CampaignStats />
    </div>
  );
};
