import type { LucideIcon } from "lucide-react";

export interface ListIconProps {
  Icon: LucideIcon;
  color: string;
  size?: number;
}

export const ListIcon = ({ Icon, color, size = 46 }: ListIconProps) => {
  return (
    <div
      className="flex items-center justify-center rounded-full bg-slate-900 relative overflow-hidden"
      style={{
        width: size,
        height: size,
      }}
    >
      <div
        className="w-full h-full absolute top-0 left-0"
        style={{
          backgroundColor: color,
          opacity: color === "#f8fafc" ? 0.03 : 0.1,
        }}
      />
      <Icon
        className="stroke-[2.5px]"
        style={{ color, width: (22 / 46) * size, height: (22 / 46) * size }}
      />
    </div>
  );
};
