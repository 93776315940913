import { ListIcon } from "@/modules/landing/leads/viewport/list-icon";
import { Button } from "@ui/components/ui/button";
import { CornerDownRight, Landmark } from "lucide-react";

export const CampaignLists = () => {
  return (
    <div className="flex gap-2 pl-[5px] text-sm mt-1.5 h-5">
      <CornerDownRight className="stroke-slate-600 w-4 h-4" />
      <div className="text-slate-500">from</div>
      <div className="flex items-center gap-1.5">
        <div className="flex items-center gap-1.5 group">
          <ListIcon Icon={Landmark} color="#22c55e" size={20} />
          <div className="font-medium text-slate-300 group-hover:text-slate-50 transition-colors">
            Ecom Agencies - NYC
          </div>
        </div>
        <Button
          size="sm"
          variant="ghost"
          className="h-5 px-2 bg-blue-300/10 text-blue-300 ml-[2px]"
        >
          +3
        </Button>
      </div>
    </div>
  );
};
