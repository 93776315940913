import { Button, buttonVariants } from "@ui/components/ui/button";
import { cn } from "@ui/lib/utils";
import {
  ChevronDown,
  ChevronUp,
  ChevronsUpDown,
  EllipsisVerticalIcon,
  type LucideIcon,
} from "lucide-react";
import Image from "next/image";

export const TopBar = () => {
  return (
    <div className="flex h-[44px] w-full items-center justify-between gap-2 border-b-[0.5px] p-[8px] px-[14px]">
      <div className="flex w-full items-center gap-4 overflow-hidden">
        <div className="flex flex-shrink items-center gap-2.5 overflow-hidden">
          <button className="group flex items-center gap-2.5" type="button">
            <Image
              src="/landing/avatars/male-sketch.png"
              width={16}
              height={16}
              className="h-4 w-4 min-w-4 flex-shrink-0 rounded-full bg-slate-800"
              alt="AdVantage Studio X account logo"
            />
            <div className="min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium text-slate-50">
              AdVantage Studio
            </div>
            <ChevronsUpDown className="h-[14px] w-[14px] text-slate-400 transition-colors group-hover:text-slate-200" />
          </button>
        </div>
      </div>
      <div className="flex gap-2">
        <div className="hidden gap-2 md:flex">
          <TooltipAction icon={ChevronUp} />
          <TooltipAction icon={ChevronDown} />
        </div>
        <Button
          size="sm"
          variant="ghost"
          className="h-6 w-6 stroke-[2.5px] p-0 text-slate-500"
        >
          <EllipsisVerticalIcon className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};

const TooltipAction: React.FC<{
  icon: LucideIcon;
}> = ({ icon: Icon }) => {
  return (
    <Button
      className={cn(
        buttonVariants({
          variant: "outline",
          size: "sm",
        }),
        "h-6 w-6 bg-slate-900 stroke-[2.5px] p-0 text-slate-400",
      )}
    >
      <Icon
        style={{
          width: 16,
          height: 16,
        }}
      />
    </Button>
  );
};
