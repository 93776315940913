import { BACKGROUND_HSL } from "@/lib/gradient";
import { Button } from "@ui/components/ui/button";
import { cn } from "@ui/lib/utils";

const tabs = ["Overview", "Prospects", "Settings"];
const activeTab = "Overview";

export const CampaignNavbar = () => {
  return (
    <div
      className="w-full border-b-[0.5px] border-borderLight z-20 px-4 text-slate-400"
      style={{
        backgroundColor: `hsl(${BACKGROUND_HSL})`,
      }}
    >
      <div className="flex items-center gap-2 relative py-2">
        {tabs.map((tab) => (
          <Tab key={tab} isActive={activeTab === tab}>
            {tab}
          </Tab>
        ))}
        <div
          className="absolute left-0 -bottom-[1px] h-[2px] bg-blue-200 rounded-full transition-all"
          style={{
            transform: `translateX(${0}px)`,
            width: `${77}px`,
          }}
        />
      </div>
    </div>
  );
};

const Tab = ({
  children,
  isActive,
}: {
  children: React.ReactNode;
  isActive: boolean;
}) => {
  return (
    <Button
      variant="ghost"
      size="sm"
      className={cn(
        "text-slate-400 h-7 focus-visible:outline-none focus-visible:ring-0 focus-within:text-primary",
        isActive && "text-primary",
      )}
    >
      {children}
    </Button>
  );
};
