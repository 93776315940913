"use client";

import styles from "@/modules/leads/lead.module.css";
import { toPeopleCount } from "@tweetdm/lib/number";
import { cn } from "@ui/lib/utils";
import { MapPin } from "lucide-react";
import Image from "next/image";
import { useEffect, useState } from "react";

export interface LeadProps {
  name: string;
  username: string;
  image: string;
  bio: string;
  location: string;
  followers: number;
  following: number;
  className?: string;
}

export const Lead = ({
  name,
  username,
  image,
  bio,
  location,
  followers,
  following,
  className,
}: LeadProps) => {
  const [refreshing, setRefreshing] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(
      () => {
        setRefreshing(false);
      },
      Math.random() * 1000 + 1000,
    );
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div
      className={cn(styles.leadGradient, "h-full relative", className)}
      data-refreshing={refreshing}
    >
      <div className="min-h-[149.5px] shadow-xl bg-[#0a1223] rounded-lg flex flex-col gap-2 p-3 px-4 z-10 relative">
        <div className="flex items-center gap-[10px] pr-3 relative z-10">
          <Image
            src={image}
            alt={`${name}'s profile picture`}
            width={28}
            height={28}
            className="size-7 rounded-full bg-slate-800 min-w-7"
          />
          <div className="flex flex-col text-sm text-slate-100 overflow-hidden">
            <div className="font-semibold whitespace-nowrap overflow-hidden text-ellipsis">
              {name}
            </div>
            <div className="text-xs text-slate-500">@{username}</div>
          </div>
        </div>
        <div className="text-xs text-slate-300 line-clamp-3 overflow-hidden relative z-10">
          {bio}
        </div>
        <div className="flex gap-2 items-center mt-1 relative z-10">
          <div className="text-[11px] text-slate-400">
            <b className="mr-[2px] text-xs font-semibold text-slate-200">
              {toPeopleCount(followers ?? 0)}
            </b>{" "}
            {followers === 1 ? "Follower" : "Followers"}
          </div>
          <div className="text-[11px] text-slate-400">
            <b className="mr-[2px] text-xs font-semibold text-slate-200">
              {toPeopleCount(following ?? 0)}
            </b>{" "}
            Following
          </div>
        </div>
        {location && location.trim() !== "" && (
          <div className="-ml-[2px] flex items-center gap-1.5 relative z-10">
            <MapPin className="h-[14px] w-[14px] stroke-slate-500 min-w-[14px]" />
            <div className="text-[11px] font-medium text-slate-400 text-ellipsis whitespace-nowrap overflow-hidden">
              {location}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
