"use client";

import { Button } from "@ui/components/ui/button";
import { Input } from "@ui/components/ui/input";
import { cn } from "@ui/lib/utils";
import {
  AtSign,
  CaseSensitive,
  type LucideIcon,
  MapPin,
  PlusIcon,
  RepeatIcon,
  TextIcon,
  Users,
} from "lucide-react";
import { useContext } from "react";
import { LeadViewportContext } from "../lead-viewport-context";
import { AdvancedFilter } from "./advanced-filter";
import { EllipsisButton } from "./ellipsis-button";
import { FilterCount } from "./filter-count";
import { FilterWrapper } from "./filter-wrapper";
import { PropertyButton } from "./property-button";
import { SimpleFilter } from "./simple-filter";

export const LeadSearchBar = ({ mobile = false }: { mobile?: boolean }) => {
  const { disableBlur } = useContext(LeadViewportContext);

  return (
    <div className="h-full w-full items-center flex flex-col gap-3 relative">
      <div
        className={cn(
          "relative rounded-lg mx-auto w-full max-w-3xl z-20 overflow-hidden mb-1",
          mobile && "max-w-5xl",
        )}
      >
        <div className="w-full h-[3px] bg-slate-800/50" />
      </div>
      <div
        className={cn(
          "flex justify-between mx-auto max-w-3xl z-20 relative items-start w-full",
          mobile && "max-w-5xl",
        )}
      >
        <div className="flex flex-wrap gap-2 px-2">
          <FilterCount />
          <FilterWrapper>
            <AdvancedFilter count={2} />
            {!disableBlur && (
              <div className="absolute left-0 top-10 bg-slate-900 p-0 rounded-lg border-[0.5px] shadow-xl">
                <div className="flex flex-col p-4 pb-1 h-full">
                  <AdvancedFilterRow isFirst value="ecommerce" />
                  <AdvancedFilterRow type="Or" value="ecom" searchAttributes />
                </div>
                <div className="w-full h-[0.5px] bg-border" />
                <div className="p-4 flex items-center">
                  <Button
                    variant="outline"
                    size="sm"
                    className="bg-transparent h-7 pl-2 text-slate-300"
                  >
                    <PlusIcon className="mr-1.5 h-3 w-3" />
                    Add sub filter
                  </Button>
                </div>
              </div>
            )}
          </FilterWrapper>
          <FilterWrapper>
            <SimpleFilter />
          </FilterWrapper>
        </div>
      </div>
    </div>
  );
};

const AdvancedFilterRow = ({
  isFirst = false,
  type,
  value,
  searchAttributes,
}: {
  isFirst?: boolean;
  type?: string;
  value: string;
  searchAttributes?: boolean;
}) => {
  return (
    <div style={{ height: 44 }}>
      <div className="flex items-center gap-2 justify-between">
        <div className="flex items-center gap-3 relative">
          <button
            className="h-7 flex items-center justify-center border-[0.5px] border-slate-700/70 px-2 rounded-lg gap-1.5 w-[58px] relative hover:bg-slate-700/30 transition-[background]"
            type="button"
            disabled={isFirst}
          >
            <TypeComponent type={type} />
          </button>
          <InteractableGroup>
            <PropertyButton>
              <TextIcon className="mr-[10px] inline h-[14px] w-[14px] stroke-slate-400/90" />
              Bio
            </PropertyButton>
            <PropertyButton>contains</PropertyButton>
            <PropertyButton>{value}</PropertyButton>
            <EllipsisButton />
          </InteractableGroup>
          {searchAttributes && <SearchAttributes />}
        </div>
      </div>
    </div>
  );
};

const SearchAttributes = () => {
  const { disableBlur } = useContext(LeadViewportContext);

  return (
    <div
      className={cn(
        "absolute left-[70px] top-10 bg-[#161E31] p-0 rounded-lg border-[0.5px] w-[200px] mt-1",
        !disableBlur && "backdrop-blur-lg bg-[#131C2Ecc] translate-x-1.5 mt-0",
      )}
      style={{
        boxShadow: "-4px -4px 50px -12px rgba(0, 0, 0, 0.35)",
      }}
    >
      <Input
        className="placeholder:text-muted-foreground/65 flex h-9 w-full bg-transparent p-1.5 px-[14px] text-sm outline-none disabled:cursor-not-allowed disabled:opacity-50 border-0 border-b-[0.5px]"
        placeholder="Search attributes..."
      />
      <div className="p-1.5">
        <Selectable name="Bio" icon={TextIcon} />
        <Selectable name="Name" icon={CaseSensitive} />
        <Selectable name="Username" icon={AtSign} />
        <Selectable name="Location" icon={MapPin} selected />
        <Selectable name="Followers" icon={Users} />
        <Selectable name="Following" icon={Users} />
        <Selectable name="Tweets" icon={RepeatIcon} />
      </div>
    </div>
  );
};

const TypeComponent = ({ type }: { type?: string }) => {
  return (
    <div
      className={cn(
        "flex items-center justify-center gap-1.5 px-2 w-[58px] absolute left-0 top-0 h-full",
        !type && "text-slate-400",
      )}
    >
      <div className="text-xs font-medium">{type ?? "Where"}</div>
      {type && <RepeatIcon className="h-3 w-3" />}
    </div>
  );
};

const InteractableGroup = ({ children }: { children: React.ReactNode }) => {
  const { disableBlur } = useContext(LeadViewportContext);

  return (
    <div
      className={cn(
        "flex w-max items-center rounded-lg bg-slate-800/50 text-xs text-slate-200 overflow-hidden",
        !disableBlur && "backdrop-blur-sm",
      )}
    >
      {children}
    </div>
  );
};

const Selectable = ({
  name,
  icon: Icon,
  selected = false,
}: { name: string; icon: LucideIcon; selected?: boolean }) => {
  return (
    <div
      className={cn(
        "data-[selected=true]:text-accent-foreground group relative flex cursor-default select-none items-center rounded-md px-[14px] py-[13px] text-sm outline-none",
        "h-7 px-[9px] data-[selected=true]:bg-slate-700/40 text-xs",
      )}
      data-selected={selected}
    >
      <Icon className="group-data-[selected=true]:text-accent-foreground mr-3 h-3 w-3 shrink-0 text-slate-400" />
      {name}
    </div>
  );
};
