import { ChatContainer } from "../../../hero/viewport/chat/chat-container";
import { Sidebar } from "../../../hero/viewport/sidebar";
import { ThreadsList } from "../../../hero/viewport/threads/threads-list";
import { TopBar } from "../../../hero/viewport/top-bar";

export const InboxView = () => {
  return (
    <>
      <div className="flex h-full flex-1 flex-col">
        <TopBar />
        <div className="flex h-[calc(100%-44px)] flex-row">
          <ThreadsList />
          <ChatContainer />
        </div>
      </div>
      <Sidebar />
    </>
  );
};
