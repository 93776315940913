import { BACKGROUND_HSL, gradient } from "@/lib/gradient";
import Image from "next/image";

const showMask = `linear-gradient(hsla(${BACKGROUND_HSL},1),hsla(${BACKGROUND_HSL},1))`;

export const Banner: React.FC<{
  data: string | React.ReactNode;
  drawer?: boolean;
}> = ({ data }) => {
  return (
    <div
      className="relative -ml-3 -mt-3 h-[81px] w-[calc(100%+24px)]"
      style={{
        maskImage: `${gradient(BACKGROUND_HSL, "bottom")}, ${showMask}`,
        maskSize: "100% 80px, 100% calc(100% - 80px)",
        maskRepeat: "no-repeat, no-repeat",
        maskPosition: "bottom, top",
      }}
    >
      {typeof data === "string" ? (
        <Image
          src={data}
          alt="banner"
          height={81}
          width={243.5}
          className="max-h-[81px] w-full rounded-md bg-slate-800/50 object-cover opacity-50"
        />
      ) : (
        data
      )}
    </div>
  );
};
