import { AnimatePresence, motion } from "framer-motion";

export const ViewWrapper = ({
  children,
  visible,
}: {
  children: React.ReactNode;
  visible: boolean;
}) => {
  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          initial={{ opacity: 0, x: -8 }}
          animate={{ opacity: 1, x: 0, transition: { delay: 0.15 } }}
          exit={{ opacity: 0, x: 8 }}
          className="absolute top-0 left-0 flex flex-1 size-full"
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
