import { cn } from "@ui/lib/utils";

export const DateSeparator = ({
  date,
  className,
}: { date: string; className?: string }) => {
  return (
    <div className="flex select-none items-center gap-2 pb-4">
      <div className={cn("bg-borderLight h-[0.5px] flex-1", className)} />
      <div className="text-xs font-semibold text-slate-400">{date}</div>
      <div className={cn("bg-borderLight h-[0.5px] flex-1", className)} />
    </div>
  );
};
