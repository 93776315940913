"use client";

import { Button } from "@ui/components/ui/button";
import { cn } from "@ui/lib/utils";
import { motion } from "framer-motion";
import type { LucideIcon } from "lucide-react";
import { InboxIcon, KanbanIcon, MagnetIcon, MegaphoneIcon } from "lucide-react";
import { usePostHog } from "posthog-js/react";
import { useState } from "react";
import { AppViewport } from "../../hero/app-viewport";
import { ViewWrapper } from "./view-wrapper";
import { CampaignsView } from "./views/campaigns";
import { InboxView } from "./views/inbox";
import { LeadsView } from "./views/leads";
import { PipelineView } from "./views/pipeline";

export const HomeViewport = () => {
  const [selectedNavItem, setSelectedNavItem] = useState("threads");

  const navItems = [
    { icon: InboxIcon, label: "Inbox", name: "threads" },
    { icon: MegaphoneIcon, label: "Campaigns", name: "campaigns" },
    { icon: MagnetIcon, label: "Leads", name: "leads" },
    { icon: KanbanIcon, label: "Pipeline", name: "pipeline" },
  ];

  return (
    <>
      <motion.div
        className="flex items-center gap-4 mx-auto pt-8 w-max mb-6"
        initial={{
          opacity: 0,
          y: 32,
        }}
        animate={{
          opacity: 1,
          y: 0,
          transition: {
            type: "spring",
            damping: 20,
            stiffness: 50,
            mass: 1,
            delay: 0.25,
          },
        }}
      >
        {navItems.map((item) => (
          <NavButton
            key={item.name}
            icon={item.icon}
            label={item.label}
            onClick={() => setSelectedNavItem(item.name)}
            active={selectedNavItem === item.name}
          />
        ))}
      </motion.div>
      <AppViewport className="max-w-6xl" selectedNavItem={selectedNavItem}>
        <div className="flex flex-1 relative">
          <ViewWrapper visible={selectedNavItem === "threads"}>
            <InboxView />
          </ViewWrapper>
          <ViewWrapper visible={selectedNavItem === "campaigns"}>
            <CampaignsView />
          </ViewWrapper>
          <ViewWrapper visible={selectedNavItem === "leads"}>
            <LeadsView />
          </ViewWrapper>
          <ViewWrapper visible={selectedNavItem === "pipeline"}>
            <PipelineView />
          </ViewWrapper>
        </div>
      </AppViewport>
    </>
  );
};

const NavButton = ({
  icon: Icon,
  label,
  onClick,
  active,
}: {
  icon: LucideIcon;
  label: string;
  onClick?: () => void;
  active?: boolean;
}) => {
  const posthog = usePostHog();

  return (
    <Button
      variant="outline"
      size="sm"
      className={cn(
        "text-slate-300 bg-slate-900/50 hover:bg-slate-800/75 rounded-lg active:bg-slate-800/60",
        active && "bg-slate-800 hover:bg-slate-800 text-primary",
      )}
      onClick={() => {
        posthog.capture("hero_viewport_nav_clicked", {
          name: label,
        });
        onClick?.();
      }}
    >
      <Icon className="size-3 mr-[10px] hidden sm:block" />
      {label}
    </Button>
  );
};
