"use client";

import { BACKGROUND_HSL, gradient } from "@/lib/gradient";
import { BentoContainer } from "@/modules/landing/layouts/bento-container";
import { cn } from "@ui/lib/utils";
import Image from "next/image";
import { useState } from "react";

const snippetsData = [
  {
    title: "Booking",
    content:
      "Sounds good! Please pick a time on my calendar at the following link: https://cal.com/kevin",
    icon: "calendar-1",
  },
  {
    title: "Follow-up",
    content:
      "Thanks for the call today! Let me know if you have any questions regarding our service",
    icon: "messages-square",
  },
  {
    title: "Politely decline",
    content:
      "We're more oriented towards direct advertising currently, I'd love to chat with you on a later",
    icon: "circle-x",
  },
  {
    title: "Issue response",
    content:
      "Thanks for bringing this up. We've forwarded this issue to our team and I'll let you know",
    icon: "badge-alert",
  },
  {
    title: "Pricing",
    content:
      "We charge a base retainer fee of $8,000 and then $1,000 per month for our service.",
    icon: "circle-dollar-sign",
  },
  {
    title: "Forward",
    content:
      "I've forwarded this to our team and we'll get back to you as soon as possible.",
    icon: "forward",
  },
  {
    title: "Affiliate",
    content:
      "If you're interested in becoming an affiliate, fill out the following form:",
    icon: "share-2",
  },
  {
    title: "Competitors",
    content:
      "Truthfully, no one else on the market can really offer the same scale at nearly",
    icon: "landmark",
  },
];

export const Snippets = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <BentoContainer
      heading="10x faster responses"
      description="Create snippets once, use them forever—all shared with your team."
    >
      <div
        className="h-[260px]"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        role="img"
        aria-label="A carousel of reusable snippets, like one titled 'Booking' with the content 'Sounds good! Please pick a time on my calendar at the following link:'"
      >
        <figure
          className="z-10 flex select-none flex-col gap-3 text-sm mt-32 relative w-[399.5px] mx-auto"
          style={{
            transformStyle: "preserve-3d",
            transformOrigin: "center",
            perspective: "5000px",
          }}
          aria-hidden="true"
        >
          <div
            className="flex flex-col"
            style={{
              transform: "rotateY(8deg)",
              transformOrigin: "center",
              transformStyle: "preserve-3d",
            }}
          >
            <div
              className="relative transition-transform duration-500 h-[36.5px]"
              style={{
                transformStyle: "preserve-3d",
                willChange: "transform",
                transformOrigin: "center",
                transform: isHovered ? "rotateX(72deg)" : "rotateX(0deg)",
              }}
            >
              {snippetsData
                .concat(snippetsData)
                .slice(0, 15)
                .map((snippet, index) => (
                  <Snippet
                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                    key={index}
                    index={index}
                    radius={110}
                    hovered={isHovered}
                    {...snippet}
                  />
                ))}
            </div>
            <div className="absolute -top-24 -left-8 w-[440px] h-[500px] bg-background/90" />
          </div>
          <div
            className="absolute -left-1 right-0 -top-24 h-[104px] bg-background"
            style={{
              maskImage: gradient(BACKGROUND_HSL, "bottom"),
              transform: "translateZ(200px)",
            }}
          />
          <div
            className="absolute -left-1 right-0 -bottom-[103.5px] h-[104px] bg-background"
            style={{
              maskImage: gradient(BACKGROUND_HSL, "top"),
              transform: "translateZ(200px)",
            }}
          />
          <div
            className="absolute -right-4 -top-24 -bottom-[140px] w-80 bg-background"
            style={{
              maskImage: gradient(BACKGROUND_HSL, "left"),
              transform: "translateZ(200px)",
            }}
          />
        </figure>
      </div>
    </BentoContainer>
  );
};

const Snippet: React.FC<{
  title: string;
  content: string;
  index: number;
  radius: number;
  icon: string;
  hovered: boolean;
}> = ({ title, content, index, radius, icon, hovered }) => {
  const angleIncrement = 360 / 15;

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        transform: `
          rotateX(${-index * angleIncrement}deg)
          translateZ(${radius}px)
        `,
        backfaceVisibility: "visible",
      }}
    >
      <div className="flex items-center gap-3 bg-slate-900/75 py-2 px-3 rounded-lg z-50 relative border-[0.5px]">
        <div className="relative size-4 shrink-0">
          <Image
            src={`/landing/icons/${icon}.png`}
            alt={title}
            width={28}
            height={28}
            className="absolute size-[28px] min-w-[28px] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
          />
        </div>
        <div className="whitespace-nowrap font-medium">{title}</div>
        <div className="overflow-hidden overflow-ellipsis whitespace-nowrap text-slate-400">
          {content}
        </div>
        <div
          className={cn(
            "absolute h-full -left-3 top-0 w-[2px] bg-slate-800 rounded-full transition-colors duration-500",
            hovered && "bg-slate-700",
          )}
        />
      </div>
    </div>
  );
};
