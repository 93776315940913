"use client";

import { BACKGROUND_HSL, gradient } from "@/lib/gradient";
import { Button } from "@ui/components/ui/button";
import { AnimatePresence, motion } from "framer-motion";
import { MenuIcon, TwitterIcon, XIcon } from "lucide-react";
import Link from "next/link";
import { usePostHog } from "posthog-js/react";
import { useEffect, useState } from "react";
import { useBreakpoint } from "use-breakpoint";
import { NavButton } from "./nav-button";

const links = [
  {
    title: "Campaigns",
    path: "/campaigns",
  },
  {
    title: "Leads",
    path: "/leads",
  },
  {
    title: "CRM",
    path: "/crm",
  },
  {
    title: "Pricing",
    path: "/pricing",
  },
  {
    title: "Blog",
    path: "/blog",
  },
];

export const Navbar = ({ children }: { children: React.ReactNode }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { breakpoint } = useBreakpoint({ mobile: 0, md: 768 });
  const isMobile = breakpoint === "mobile";
  const posthog = usePostHog();

  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    setInitialized(true);
  }, []);

  return (
    <>
      <div
        data-visible={mobileOpen}
        className="fixed top-0 left-0 w-full h-full bg-background/75 z-50 pointer-events-none opacity-0 data-[visible=true]:pointer-events-auto data-[visible=true]:opacity-100 transition-opacity md:hidden"
        onClick={() => setMobileOpen(false)}
      />
      <nav
        data-expanded={mobileOpen}
        className="fixed left-1/2 top-4 z-[100] flex flex-col w-[calc(100%-32px)] max-w-4xl -translate-x-1/2 rounded-2xl border border-slate-800/70 backdrop-blur-xl transition-[height,background] overflow-hidden bg-transparent data-[expanded=true]:bg-slate-900/50"
        style={{
          WebkitBackdropFilter: "blur(24px)",
          height: isMobile && mobileOpen ? 257 : 42,
        }}
      >
        <div className="flex h-[40px] w-full gap-2 md:gap-4 px-1 pr-3 justify-between items-center shrink-0">
          <ul className="flex items-center gap-x-6 w-full">
            <li>
              <Link className="flex items-center gap-2 px-3" href="/">
                <div className="relative size-4">
                  <TwitterIcon className="size-4 stroke-[2.25px] text-slate-50" />
                  <TwitterIcon
                    className="absolute top-0 left-0 size-4 stroke-[2.25px] text-slate-400"
                    style={{
                      maskImage: gradient(BACKGROUND_HSL, "top"),
                    }}
                  />
                </div>
                <div className="text-xs font-semibold text-slate-50 relative">
                  TweetDM
                </div>
              </Link>
            </li>
            <li>
              <ul className="items-center gap-6 hidden md:flex">
                {links.map((link) => (
                  <NavButton key={link.path} path={link.path}>
                    {link.title}
                  </NavButton>
                ))}
              </ul>
            </li>
          </ul>
          {children}
          <Button
            className="md:hidden p-2 hover:bg-transparent"
            variant="ghost"
            size="sm"
            onClick={() => setMobileOpen(!mobileOpen)}
            aria-label="Toggle menu"
          >
            <div className="size-4 relative">
              <AnimatePresence>
                {!mobileOpen && (
                  <motion.div
                    initial={{
                      opacity: initialized ? 0 : 1,
                      scale: initialized ? 0.6 : 1,
                    }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.6 }}
                    className="absolute top-0 left-0"
                  >
                    <MenuIcon className="size-4 text-slate-300" />
                  </motion.div>
                )}
              </AnimatePresence>
              <AnimatePresence>
                {mobileOpen && (
                  <motion.div
                    initial={{ opacity: 0, scale: 0.6 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.6 }}
                    className="absolute top-0 left-0"
                  >
                    <XIcon className="size-4 text-slate-300" />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </Button>
        </div>
        <div className="w-full mt-3 px-4 pb-5 md:hidden">
          <ul className="flex flex-col gap-3">
            {links.map((link) => (
              <li key={link.path}>
                <Link
                  href={link.path}
                  className="text-lg font-medium w-full"
                  onClick={(e) => {
                    posthog.capture("nav_link_clicked", {
                      name: link.title,
                    });
                    setMobileOpen(false);
                  }}
                >
                  {link.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </>
  );
};
