"use client";

import { campaignEventChannel } from "@/events/campaigns";
import { useEffect } from "react";

export const CampaignClockInterval = () => {
  useEffect(() => {
    const interval = setInterval(() => {
      campaignEventChannel.emit("clockInterval");
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return null;
};
