export const tags = [
  {
    name: "B2B",
    color: "#ef4444",
  },
  {
    name: "Referral",
    color: "#3b82f6",
  },
  {
    name: "Europe",
    color: "#a855f7",
  },
];
