"use client";

import { Button } from "@ui/components/ui/button";
import Link from "next/link";
import { usePostHog } from "posthog-js/react";
import { bookingUrl } from "../../common/urls";

export const CtaButtons = () => {
  const posthog = usePostHog();

  return (
    <div className="mt-12 flex items-center gap-4">
      <Link href="/auth/login">
        <Button
          className="w-max px-4 text-base rounded-xl"
          size="lg"
          onClick={() => {
            posthog.capture("primary_cta_clicked", { location: "cta" });
          }}
        >
          Get started
        </Button>
      </Link>
      <Link href={bookingUrl} target="_blank">
        <Button
          className="w-max px-4 text-base rounded-xl backdrop-blur-sm"
          size="lg"
          variant="outline"
          onClick={() => {
            posthog.capture("book_demo_clicked", { location: "cta" });
          }}
        >
          Book a demo
        </Button>
      </Link>
    </div>
  );
};
