import { Button } from "@ui/components/ui/button";
import { EllipsisVertical } from "lucide-react";

export const EllipsisButton = () => {
  return (
    <Button
      variant="ghost"
      className="p-2 rounded-none focus-visible:ring-0"
      size="sm"
    >
      <EllipsisVertical className="h-3 w-3 stroke-slate-400" />
    </Button>
  );
};
