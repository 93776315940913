import {
  ChevronDown,
  Inbox,
  Kanban,
  Magnet,
  Megaphone,
  Plus,
  Settings,
  UserPlus2,
} from "lucide-react";
import { NavContainer } from "./navbar/nav-container";

import { Button } from "@ui/components/ui/button";

export const ViewportNavbar = ({
  selectedNavItem,
}: { selectedNavItem?: string }) => {
  return (
    <div className="flex h-full w-[180px] flex-col px-[14px]">
      <div className="mt-2">
        <div className="flex h-[40px] items-center gap-[10px] pl-[6px]">
          <div className="h-5 w-5 rounded-md bg-gradient-to-tr from-purple-400 via-blue-300 to-sky-300" />
          <div className="flex min-w-0 items-center gap-[6px] overflow-hidden">
            <div className="max-w-[100px] overflow-hidden overflow-ellipsis whitespace-nowrap text-sm font-semibold">
              AdVantage
            </div>
            <ChevronDown className="h-4 w-4 text-slate-400" />
          </div>
        </div>
      </div>
      <div className="mb-[18px] mt-2 px-1">
        <Button
          variant="outline"
          className="flex h-[28px] w-full justify-between rounded-md bg-slate-800/25 px-[8px] text-slate-300 hover:bg-slate-800/75"
          size="sm"
        >
          <span className="font-semibold">Quick actions</span>
          <span className="rounded-sm border p-[1px] px-1 text-xs text-slate-400">
            ⌘K
          </span>
        </Button>
      </div>
      <NavContainer
        items={[
          {
            title: "Inbox",
            icon: Inbox,
            isActive: selectedNavItem === "threads",
            subItems: [
              {
                title: "My threads",
              },
              {
                title: "Unread",
              },
              {
                title: "No reply",
              },
              {
                title: "Archived",
              },
            ],
          },
          {
            title: "Pipeline",
            icon: Kanban,
            isActive: selectedNavItem === "pipeline",
          },
          {
            title: "Campaigns",
            icon: Megaphone,
            isActive: selectedNavItem === "campaigns",
          },
          {
            title: "Leads",
            icon: Magnet,
            separator: true,
            isActive: selectedNavItem === "leads",
          },
          {
            title: "Invite members",
            icon: UserPlus2,
            isActive: selectedNavItem === "invite",
          },
          {
            title: "Link an account",
            icon: Plus,
            separator: true,
            isActive: selectedNavItem === "link",
          },
          {
            title: "Settings",
            icon: Settings,
            isActive: selectedNavItem === "settings",
          },
        ]}
      />
    </div>
  );
};
