import { Button, type ButtonProps } from "@ui/components/ui/button";
import { cn } from "@ui/lib/utils";
import Link from "next/link";
import { usePostHog } from "posthog-js/react";

export const NavButton = ({
  children,
  className,
  path,
  onClick,
  ...props
}: ButtonProps & {
  path: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}) => {
  const posthog = usePostHog();

  return (
    <li>
      <Button
        variant="ghost"
        className={cn(
          "text-slate-300/90 hover:bg-transparent px-2 text-xs",
          className,
        )}
        asChild
        onClick={(e) => {
          posthog.capture("nav_link_clicked", {
            name: children,
          });

          onClick?.(e);
        }}
        {...props}
      >
        <Link href={path}>{children}</Link>
      </Button>
    </li>
  );
};
