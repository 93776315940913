import { Button } from "@ui/components/ui/button";
import { ChevronDown, ChevronUp, type LucideIcon } from "lucide-react";

export const TopBar = () => {
  return (
    <div className="flex h-[44px] w-full items-center justify-between gap-2 border-b-[0.5px] p-[8px] px-[14px]">
      <div className="flex w-full items-center gap-4 overflow-hidden">
        <div className="ml-2 text-sm font-medium">All threads</div>
      </div>
      <div className="flex gap-2">
        <NavButton icon={ChevronUp} />
        <NavButton icon={ChevronDown} />
      </div>
    </div>
  );
};

const NavButton = ({ icon: Icon }: { icon: LucideIcon }) => {
  return (
    <Button
      size="sm"
      variant="outline"
      className="h-6 w-6 bg-slate-900 stroke-[2.5px] p-0 text-slate-400"
    >
      <Icon style={{ width: 16, height: 16 }} />
    </Button>
  );
};
