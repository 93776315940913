import { tags } from "../sidebar/tags";
import type { ThreadProps } from "./thread";

export const threads: ThreadProps[] = [
  {
    image: "/landing/avatars/astronaut.png",
    name: "Matthew Ortiz",
    lastMessage: "yeah, for the most part at least we're trying to",
    time: "now",
    isUnread: true,
  },
  {
    image: "/landing/avatars/kevin.jpg",
    name: "Kevin",
    lastMessage: "works for me!",
    time: "5m",
    tags,
  },
  {
    image: "/landing/avatars/superhuman.jpg",
    name: "Superhuman",
    lastMessage: "We're definitely big fans for sure",
    time: "11m",
    lastMessageAuthorImage: "/landing/avatars/ethan.png",
  },
  {
    image: "/landing/avatars/zohaib.jpg",
    name: "Zohaib Rattu",
    lastMessage: "lmk if that timeline works or we can try",
    time: "14m",
    lastMessageAuthorImage: "/landing/avatars/generic.png",
    tags: [{ color: "#f59e0b", name: "D2C" }],
  },
  {
    image: "/landing/avatars/wink.png",
    name: "Greg | 10x Your Outreach",
    lastMessage: "what stage would that be considered",
    time: "20m",
    tags: tags.filter((x) => x.name === "Referral"),
    isUnread: true,
  },
  {
    image: "/landing/avatars/ghost.png",
    name: "Nick Hammes 🚀",
    lastMessage: "did you end up deciding if we should",
    lastMessageAuthorImage: "/landing/avatars/ethan.png",
    time: "25m",
  },
  {
    image: "/landing/avatars/brand.png",
    name: "Serenity Consulting",
    lastMessage: "Hey, just checking in—got any updates?",
    lastMessageAuthorImage: "/landing/avatars/generic.png",
    time: "32m",
    tags: tags.filter((x) => ["B2B", "Europe"].includes(x.name)),
  },
  {
    image: "/landing/avatars/ghost-2.jpeg",
    name: "Ignacio Bradtke",
    lastMessage: "currently blaming 'market conditions' on our failed",
    time: "1h",
  },
  {
    image: "/landing/avatars/neo.png",
    name: "Davion 🕶️",
    lastMessage: "2 of our clients decided to drop last minute",
    time: "1h",
    tags: tags.filter((x) => x.name === "Referral"),
  },
  {
    image: "/landing/avatars/pond.png",
    name: "Crator Brands",
    lastMessage: "Hey, just checking in—got any updates?",
    lastMessageAuthorImage: "/landing/avatars/ethan.png",
    time: "1h",
  },
  {
    image: "/landing/avatars/interior-design.jpeg",
    name: "Interior",
    lastMessage: "Hey, just checking in—got any updates?",
    lastMessageAuthorImage: "/landing/avatars/ethan.png",
    time: "3h",
    tags: [
      {
        color: "#ec4899",
        name: "B&M",
      },
      {
        color: "#ef4444",
        name: "Unavailable",
      },
    ],
  },
  {
    image: "/landing/avatars/male-sketch.png",
    name: "Carter",
    lastMessage: "Yeah, that'd be great actually if you're",
    time: "3h",
    tags: [{ color: "#14b8a6", name: "NYC" }],
  },
  {
    image: "/landing/avatars/stripe.jpg",
    name: "Stripe",
    lastMessage: "Hi! We were wondering if you could help",
    time: "4h",
  },
  {
    image: "/landing/avatars/bmw.png",
    name: "Joseph | Gym Scale",
    lastMessage: "Hey, just checking in—got any updates?",
    time: "4h",
    tags: [
      { color: "#14b8a6", name: "NYC" },
      { color: "#ec4899", name: "B&M" },
    ],
  },
  {
    image: "/landing/avatars/sunset.png",
    name: "Ashley Terry",
    lastMessage: "hi Ashley, were you able to get the",
    time: "4h",
  },
];
