"use client";

import { BACKGROUND_HSL, gradient } from "@/lib/gradient";
import { cn } from "@ui/lib/utils";
import { Lead } from "./lead";
import { leads } from "./leads";

const showMask = `linear-gradient(hsla(${BACKGROUND_HSL},1),hsla(${BACKGROUND_HSL},1))`;

export const LeadSearch = ({
  fullOpacity = false,
  mobile = false,
}: { fullOpacity?: boolean; mobile?: boolean }) => {
  return (
    <div
      className={cn(
        "mx-auto max-w-3xl mt-12 px-1 -translte-x-1 w-[calc(100%+16px)] pb-2",
        mobile && "ml-4",
      )}
      style={{
        maskImage: `${gradient(BACKGROUND_HSL, "top")}, ${showMask}`,
        maskSize: "100% 56px, 100% calc(100% - 56px)",
        maskRepeat: "no-repeat, no-repeat",
        maskPosition: "top, bottom",
      }}
    >
      <div className="w-full grid grid-cols-3 gap-4 gap-x-5 -mt-16">
        {leads.map((lead) => (
          <Lead
            key={lead.username}
            {...lead}
            className={!fullOpacity ? "opacity-35" : ""}
          />
        ))}
      </div>
    </div>
  );
};
