import { motion } from "framer-motion";
import { TrendingUp } from "lucide-react";

const skeletonHeights = [
  95, 120, 145, 165, 155, 175, 135, 115, 85, 70, 95, 130, 180, 160, 125, 105,
  80, 65, 110, 140, 155, 130, 100, 90,
];

export const MessagesChart = () => {
  return (
    <div className="mt-6 rounded-lg border-[0.5px] p-3 px-4 bg-slate-900">
      <div className="flex flex-col mb-4 gap-[2px]">
        <div className="font-medium flex items-center gap-2">
          <TrendingUp className="w-4 h-4" />
          Messages
        </div>
        <div className="text-slate-400 text-sm">
          Campaign performance for the last 24 hours
        </div>
      </div>
      <div className="relative h-[250px]">
        <div className="w-full gap-[8px] absolute top-0 bottom-8 items-center left-0 flex px-[15px]">
          {skeletonHeights.map((height, i) => (
            <motion.div
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              key={i}
              className="w-full rounded-sm bg-gradient-to-b from-blue-600 to-transparent"
              initial={{
                height: 0,
              }}
              animate={{
                height,
              }}
              transition={{
                delay: 0.025 * i,
                bounceDamping: 0,
                damping: 20,
                velocity: 200,
                type: "spring",
              }}
            />
          ))}
        </div>
        <div className="w-full gap-[8px] absolute top-0 bottom-8 items-center left-0 flex px-[15px]">
          {skeletonHeights.map((height, i) => (
            <motion.div
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              key={i}
              className="w-full rounded-sm bg-gradient-to-b from-blue-400 to-transparent"
              initial={{
                height: 0,
              }}
              animate={{
                height: height / 5,
              }}
              transition={{
                delay: 0.025 * i + 0.2,
                bounceDamping: 0,
                damping: 20,
                velocity: 200,
                type: "spring",
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
