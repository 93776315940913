"use client";

import { env } from "@tweetdm/env/client";
import Script from "next/script";
import { create } from "zustand";

declare global {
  interface Window {
    twq: (command: string, eventId: string, params?: unknown) => void;
  }
}

interface TwitterPixelStore {
  isLoaded: boolean;
  setLoaded: () => void;
}

const useTwitterPixelStore = create<TwitterPixelStore>((set) => ({
  isLoaded: false,
  setLoaded: () => set({ isLoaded: true }),
}));

export const getTwq = async (timeout = 5000): Promise<typeof window.twq> => {
  const { isLoaded } = useTwitterPixelStore.getState();

  if (isLoaded && typeof window.twq === "function") {
    return window.twq;
  }

  return new Promise((resolve, reject) => {
    const startTime = Date.now();

    const checkTwq = () => {
      if (typeof window.twq === "function") {
        resolve(window.twq);
        return;
      }

      if (Date.now() - startTime >= timeout) {
        reject(new Error("Twitter pixel load timeout"));
        return;
      }

      setTimeout(checkTwq, 100);
    };

    checkTwq();
  });
};

export const TwitterPixel = () => {
  const { setLoaded } = useTwitterPixelStore();
  if (!env.NEXT_PUBLIC_TWITTER_PIXEL_ID) return null;

  return (
    <Script
      id="twitter-pixel"
      strategy="afterInteractive"
      onLoad={() => setLoaded()}
      // biome-ignore lint/security/noDangerouslySetInnerHtml: allowed for tracking pixel
      dangerouslySetInnerHTML={{
        __html: `
        !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
        },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
        a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
        twq('config','${env.NEXT_PUBLIC_TWITTER_PIXEL_ID}');
        `,
      }}
    />
  );
};
