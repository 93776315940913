import { cn } from "@ui/lib/utils";
import { Cta } from "../hero/cta";

export const easeInOutQuart = "cubic-bezier(0.83, 0, 0.17, 1)";
export const easeOutQuart = "cubic-bezier(0.25, 1, 0.5, 1)";

export const titleAnimation = `
  @keyframes title {
    0% { opacity: 0; transform: translateY(-32px); }
    100% { opacity: 1; transform: translateY(0); }
  }
`;
export const subAnimation = `
  @keyframes sub {
    0% { opacity: 0; transform: translateY(50px); }
    100% { opacity: 1; transform: translateY(0); }
  }
`;
export const ctaAnimation = `
  @keyframes cta {
    0% { opacity: 0; transform: translateY(50px); }
    100% { opacity: 1; transform: translateY(0); }
  }
`;

export const HeroLayout = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <section className={cn("relative w-full pt-28", className)}>
      <style>{titleAnimation}</style>
      <style>{subAnimation}</style>
      <style>{ctaAnimation}</style>
      {children}
    </section>
  );
};

HeroLayout.InnerContainer = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={cn("mx-auto max-w-4xl relative", className)}>
      <div
        className="absolute top-0 right-0 h-[1000px] w-[600px] rotate-45 -translate-y-[350px] pointer-events-none rounded-full"
        style={{
          background:
            "radial-gradient(68.54% 68.72% at 55.02% 31.46%, #0f172a 0,#0f172a11 50%, transparent 80%)",
        }}
      />
      <div
        className="absolute top-0 right-0 rotate-45 w-[250px] h-[1000px] rounded-full -translate-y-[520px] -translate-x-[150px]"
        style={{
          background:
            "radial-gradient(50% 50% at 50% 50%, #1e293bdd 0, #1e293b11 80%, transparent 100%)",
        }}
      />
      {children}
    </div>
  );
};

HeroLayout.CopyContainer = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        "relative z-30 mb-16 flex flex-col justify-center px-8 items-center sm:items-start",
        className,
      )}
    >
      {children}
    </div>
  );
};

HeroLayout.Heading = ({
  children,
  className,
}: { children: React.ReactNode; className?: string }) => {
  return (
    <h1
      className={cn(
        "relative mt-0 sm:mt-5 w-full max-w-2xl text-5xl font-medium leading-[1.1] sm:text-6xl text-center sm:text-start",
        className,
      )}
      style={{
        animation: `title 0.6s ${easeInOutQuart}`,
      }}
    >
      <span
        className="absolute left-0 top-0 blur-sm opacity-30 pointer-events-none select-none w-full"
        aria-hidden="true"
      >
        {children}
      </span>
      <div className="relative">{children}</div>
    </h1>
  );
};

HeroLayout.SubHeading = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <h2
      className={cn(
        "mt-8 max-w-md text-lg font-medium text-slate-300/75 sm:text-xl text-center sm:text-start",
        className,
      )}
      style={{
        animation: `sub 0.75s ${easeOutQuart} 0.2s normal backwards`,
      }}
    >
      {children}
    </h2>
  );
};

HeroLayout.Cta = () => <Cta />;

HeroLayout.Root = ({ children }: { children: React.ReactNode }) => {
  return <section className="relative w-full pt-28">{children}</section>;
};
