"use client";

import { Button } from "@ui/components/ui/button";
import { cn } from "@ui/lib/utils";
import { ChevronRight } from "lucide-react";
import Link from "next/link";
import { usePostHog } from "posthog-js/react";

export const CtaButton = ({
  primary = false,
  plan,
}: {
  primary?: boolean;
  plan: string;
}) => {
  const posthog = usePostHog();

  return (
    <Link href="/auth/login" className="mt-auto">
      <Button
        className={cn(
          "w-full rounded-xl h-9 relative group",
          !primary && "hover:bg-accent/60",
        )}
        variant={primary ? "default" : "outline"}
        onClick={() => {
          posthog.capture("pricing_cta_clicked", {
            plan,
          });
        }}
      >
        Get started
        <div className="size-4 relative flex items-center ml-1.5">
          <div className="w-[16px] ml-2 h-[2px] bg-current rounded-full opacity-0 group-hover:opacity-100 transition-opacity" />
          <ChevronRight className="absolute top-0 left-0 size-4 stroke-[3px] transition-transform group-hover:translate-x-[7px]" />
        </div>
      </Button>
    </Link>
  );
};
