"use client";

import { BACKGROUND_HSL, gradient } from "@/lib/gradient";
import {
  ExternalDetails,
  type ExternalDetailsProps,
} from "@/modules/landing/hero/viewport/sidebar/external-details";
import { BentoContainer } from "@/modules/landing/layouts/bento-container";
import { cn } from "@ui/lib/utils";
import { useState } from "react";

const xOffset = 74;

export const NeverMiss = () => {
  return (
    <BentoContainer
      heading="Never miss a deal"
      description="Track contacts, conversations, leads, and engagement. Leave no opportunity behind."
    >
      <figure className="flex flex-col gap-4 mt-8 relative max-w-max w-full mx-auto pointer-events-none select-none">
        <div
          className="w-[360px] h-[290px] overflow-hidden"
          style={{
            perspective: "2000px",
            transformStyle: "preserve-3d",
          }}
        >
          <SkewedProfile
            displayName="Kevin"
            username="KevinPicchi"
            banner={
              <div className="size-full bg-gradient-to-r from-slate-900 to-violet-400 rounded-t-md" />
            }
            image="/landing/avatars/kevin.jpg"
            description={<>Product & Marketing 💬</>}
            followers="936"
            following="711"
            location="London & Switzerland 🇨🇭"
            url="tweetdm.com"
            translateX={xOffset}
            translateZ={-160}
            className="opacity-40"
          />
          <SkewedProfile
            displayName="Ethan Lerner"
            username="ethanleonlerner"
            banner={
              <div className="size-full bg-gradient-to-r from-slate-900 to-blue-400 rounded-t-md" />
            }
            image="/landing/avatars/ethan.png"
            description={<>Scaling startups</>}
            followers="119.7K"
            following="403"
            location="New York, NY"
            url="tweetdm.com"
            translateX={xOffset + 20}
            translateZ={-80}
            className="opacity-70"
          />
          <SkewedProfile
            displayName="Ignacio Bradtke"
            username="ignacio_bradtke"
            banner={<StylizedBanner />}
            image="/landing/avatars/ghost-2.jpeg"
            description="Serial entrepreneur & investor."
            followers="12.3K"
            following="672"
            location="New York, NY"
            translateX={xOffset + 40}
            url="ignaciobradtke.com"
          />
        </div>
        <div
          className="absolute bottom-0 h-32 bg-slate-950 w-full z-50"
          style={{
            maskImage: gradient(BACKGROUND_HSL, "top"),
          }}
        />
      </figure>
    </BentoContainer>
  );
};

const SkewedProfile = (
  props: ExternalDetailsProps & {
    translateX?: number;
    translateZ?: number;
    translateY?: number;
    className?: string;
  },
) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="absolute top-0 left-0 w-[250px] backdrop-blur-lg pointer-events-auto transition-transform duration-300"
      style={{
        transform: `rotateY(30deg) rotateX(12deg) translateX(${
          props.translateX ?? 0
        }px) translateY(${props.translateY ?? 0}px) translateZ(${
          (props.translateZ ?? 0) + (isHovered ? 30 : 0)
        }px)`,
      }}
    >
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={cn(
          "bg-slate-900/50 transition-[background-color,opacity] duration-300 rounded-xl border-[0.5px] shadow-2xl pointer-events-auto select-none",
          isHovered && "bg-slate-800/50",
          props.className,
        )}
      >
        <ExternalDetails {...props} />
      </div>
    </div>
  );
};

const StylizedBanner = () => {
  return (
    // biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
    <svg
      viewBox="0 0 210 80"
      className="w-full h-full"
      preserveAspectRatio="none"
    >
      {Array.from({ length: 21 }).map((_, index) => (
        <rect
          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
          key={index}
          x={index * 10}
          y={0}
          width={1.5}
          height={80}
          rx={0.75}
          ry={2}
          fill="url(#gradient)"
          style={{
            opacity: 1 - Math.abs(10 - index) / 10,
          }}
        />
      ))}
      <defs>
        <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor="#bfdbfe" /> {/* blue-200 */}
          <stop offset="100%" stopColor="#3b82f6" /> {/* blue-500 */}
        </linearGradient>
      </defs>
    </svg>
  );
};
