import type { LeadProps } from "./lead";

export const leads = [
  {
    name: "Steve Woda",
    username: "SteveWoda",
    bio: "Founder of buySAFE. Co-founder of uKnow and uKnowKids. Entrepreneur. Mobile & Internet Safety & Security. eCommerce. Economics. Wharton MBA. https://t.co/0ZjA4W3hWY",
    image: "/landing/avatars/leads/steve-woda.jpg",
    followers: 49167,
    following: 27421,
    location: "Arlington, VA, United States",
  },
  {
    name: "Cinephemeride [Fabrice]",
    username: "Cinephemeride",
    bio: "Boutique @Cinephemeride2 #DVD #Livres #Revues #TradingCards #ProWrestling \nFestivalier #Canneseries #Cannes2025 #MonteCarloTVFestival #Deauville2025",
    image: "/landing/avatars/leads/cinephemeride.jpg",
    followers: 27661,
    following: 23403,
    location: "Villefranche-sur-Saône, France",
  },
  {
    name: "Brady",
    username: "DigitalBraid",
    bio: "Strategy Director, SEO & Growth | Enterprise SEO Strategy & Technical SEO | 8 Fig Ecom Entrepreneur | 10mm+ Social Media | I love â˜•ï¸ , ðŸŽ®, ðŸŽ£ & my ðŸ¦®",
    image: "/landing/avatars/leads/digital-braid.jpg",
    followers: 25137,
    following: 286,
    location: "New York, USA",
  },
  {
    name: "Jordan Rinaldo",
    username: "Rinaldeens",
    bio: "Digital Marketer, Ecommerce Expert, Entrepreneur, Foodie, Beer Leaguer, Music addict, Toronto Sports Fan, Cinephile, Outdoorsman & Tough Mudder certified!",
    image: "/landing/avatars/leads/rinaldeens.jpg",
    followers: 20581,
    following: 16149,
    location: "Toronto, ON",
  },
  {
    name: "Matt Thomas",
    username: "insidersecrets",
    bio: "Online entrepreneur since '99. Interested in 💵 Affiliate Marketing #️⃣ Social Media Marketing ✍️ Blogging 📺 Video 🎙️ Podcasting and 🛒 eCommerce.",
    image: "/landing/avatars/leads/insider-secrets.jpg",
    followers: 20373,
    following: 17622,
    location: "USA",
  },
  {
    name: "Jaydip Parikh",
    username: "JaydipParikh",
    bio: "Motto: Learn, Unlearn & Repeat. \nPassion: SEO, B2B, eCom & Digital Marketing & @TheSEOTalker\nWrites: @Entrepreneur @sap @semrush @e27co\nBðŸž&BðŸ§ˆ:  @tejsolpro",
    image: "/landing/avatars/leads/jaydip-parikh.jpg",
    followers: 21585,
    following: 671,
    location: "India",
  },
  {
    name: "William Harris",
    username: "wmharris101",
    bio: "13 Exits | Top 35 #Ecommerce Expert | Top 75 #SaaS Influencer | National Speaker | Contributor @FastCompany @Entrepreneur #dtc #retail #advertising",
    image: "/landing/avatars/leads/wmharris-101.jpg",
    followers: 20188,
    following: 6320,
    location: "St. Paul, Minnesota",
  },
  {
    name: "Ricky Amador",
    username: "ecomricky",
    bio: "Ex-healthcare worker turned ecom entrepreneur. I sell products online while traveling the world. Enroll in my FREE 8 hour branded dropshipping course below:",
    image: "/landing/avatars/leads/ecom-ricky.jpg",
    followers: 17393,
    following: 113,
    location: "Dropship To Freedom:",
  },
  {
    name: "Dino Vedo",
    username: "DinoVedo",
    bio: "Entrepreneur, Investor, Ecommerce at @vedomedia. On the hustle. Follow my IG https://t.co/LiL8Ma4UYb",
    image: "/landing/avatars/leads/dino-vedo.jpg",
    followers: 16105,
    following: 4462,
    location: "United States",
  },
  {
    name: "Matt Edmundson",
    username: "mattedmundson",
    bio: "Husband + Dad + entrepreneur + podcaster + pastor. Host of the Ecommerce Podcast, Push to be More and Podjunction. CEO of Aurion https://t.co/qMWb6rJ2vV",
    image: "/landing/avatars/leads/matt-edmundson.jpg",
    followers: 15289,
    following: 90,
    location: "Liverpool, England",
  },
  {
    name: "Robert Leonard",
    username: "T4Leonard",
    bio: "Marketing Strategist Print, Web & Social. 33 yr Entrepreneur - all about Local & Localization. Built Internet News & eCommerce Localization Tools.",
    image: "/landing/avatars/leads/t4-leonard.jpg",
    followers: 13843,
    following: 12512,
    location: "Florida, USA",
  },
  {
    name: "Kriss Berg, etc.",
    username: "KrissBergTweets",
    bio: "I like to make things better. More fun, more love, more wealth, more freedom. Follow along as I figure it out. Free newsletter signup below ðŸ‘‡",
    image: "/landing/avatars/leads/kriss-berg-tweets.jpg",
    followers: 27609,
    following: 779,
    location: "Colorado",
  },
] satisfies LeadProps[];
