import { LinkIcon, type LucideIcon, MapPin } from "lucide-react";
import Image from "next/image";
import type React from "react";
import { Banner } from "./banner";

export interface ExternalDetailsProps {
  image: string;
  displayName: string;
  username: string;
  following: string;
  description: React.ReactNode;
  followers: string;
  banner: string | React.ReactNode;
  location: string;
  url: string;
}

export const ExternalDetails: React.FC<ExternalDetailsProps> = ({
  image,
  displayName,
  username,
  following,
  followers,
  banner,
  description,
  location,
  url,
}) => {
  return (
    <div className="flex flex-col gap-5 p-4 px-5 pt-5">
      <Banner data={banner} />
      <div className="z-20 -mt-8 space-y-5">
        <div className="flex items-center gap-[14px]">
          <Image
            src={image}
            className="h-[36px] w-[36px] flex-shrink-0 rounded-full bg-slate-800"
            width={36}
            height={36}
            alt={displayName ?? "Profile picture"}
          />
          <div className="flex flex-col gap-[3px]">
            <div className="font-semibold leading-[18px] text-slate-100">
              {displayName}
            </div>
            <div className="text-xs text-slate-300">@{username}</div>
          </div>
        </div>
        <div className="space-y-4">
          <div className="whitespace-pre-wrap text-sm font-medium text-slate-200">
            {description}
          </div>
          <div className="flex flex-col gap-1 font-medium text-slate-400">
            <div className="text-xs">
              <b className="mr-[2px] font-bold text-slate-200">{followers}</b>{" "}
              Followers
            </div>
            <div className="text-xs">
              <b className="mr-[2px] font-bold text-slate-200">{following}</b>{" "}
              Following
            </div>
          </div>
          <div className="space-y-2">
            <div className="flex items-center gap-2 text-slate-400">
              <ProfileIcon icon={MapPin} />
              <div className="overflow-hidden overflow-ellipsis whitespace-nowrap text-xs font-medium">
                {location}
              </div>
            </div>
            <div className="flex items-center gap-2">
              <ProfileIcon icon={LinkIcon} />
              <div className="overflow-hidden overflow-ellipsis whitespace-nowrap text-xs font-medium text-blue-400">
                {url}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProfileIcon: React.FC<{ icon: LucideIcon }> = ({ icon: Icon }) => {
  return <Icon className="h-[14px] w-[14px] flex-shrink-0 text-slate-500" />;
};
