import { TextIcon } from "lucide-react";
import { EllipsisButton } from "./ellipsis-button";
import { PropertyButton } from "./property-button";

export const SimpleFilter = () => {
  return (
    <>
      <PropertyButton>
        <TextIcon className="mr-[10px] inline h-[14px] w-[14px] stroke-slate-400/90" />
        Bio
      </PropertyButton>
      <PropertyButton>contains</PropertyButton>
      <PropertyButton>entrepreneur</PropertyButton>
      <EllipsisButton />
    </>
  );
};
