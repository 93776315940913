"use client";

import { threads } from "@/modules/landing/hero/viewport/threads";
import { cn } from "@ui/lib/utils";
import { motion, useAnimate } from "framer-motion";
import { useEffect } from "react";
import useBreakpoint from "use-breakpoint";
import { mainAssignee, secondaryAssignee } from "./asignees";
import { ColumnsContainer } from "./columns-container";
import { Container } from "./container";
import { Cursor } from "./cursor";
import { PipelineThread } from "./pipeline-thread";
import { TopBar } from "./top-bar";

export const PipelineView = () => {
  const [scope, animate] = useAnimate();
  const [cursorScope, cursorAnimate] = useAnimate();
  const { breakpoint } = useBreakpoint({ minimized: 0, full: 550 });

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    void (async () => {
      void (async () => {
        await new Promise((resolve) => setTimeout(resolve, 1000));

        if (!cursorScope.current) return;
        await cursorAnimate(cursorScope.current, {
          opacity: 1,
          scale: 1,
          transition: {
            delay: 1.8,
          },
        });
      })();

      if (!scope.current) return;
      await animate(
        scope.current,
        {
          x: [null, null, null, 308],
          y: [0, 0, 4, 12],
          rotateZ: [0, -4, null, null],
        },
        {
          duration: 1.5,
          delay: 1.8,
          ease: "easeInOut",
          times: [0, 0.1, 0.2, 1],
        },
      );

      if (!cursorScope.current) return;
      void cursorAnimate(cursorScope.current, {
        opacity: 0,
      });

      if (!scope.current) return;
      await animate(
        scope.current,
        {
          x: 308,
          y: -2,
          rotateZ: 0,
        },
        {
          ease: "easeOut",
          duration: 0.2,
        },
      );
    })();
  }, []);

  return (
    <div className="flex flex-col size-full">
      <TopBar />
      <div
        className={cn(
          "relative flex h-full flex-1 overflow-hidden",
          breakpoint === "minimized" && "-translate-x-[305px]",
        )}
      >
        <ColumnsContainer>
          <Container color="#64748b" status="Cold">
            <PipelineThread {...threads[2]!} />
            <PipelineThread {...threads[6]!} assignee={mainAssignee} />
            <PipelineThread {...threads[12]!} />
            <PipelineThread {...threads[7]!} />
            <PipelineThread {...threads[11]!} assignee={mainAssignee} />
          </Container>
          <Container color="#f59e0b" status="Warm">
            <PipelineThread {...threads[0]!} />
            <motion.div
              ref={scope}
              className="z-20"
              initial={{
                x: 0,
                y: 0,
              }}
            >
              <PipelineThread
                {...threads[1]!}
                lastMessage="just signed the agreement, thanks once again"
                assignee={mainAssignee}
              />
            </motion.div>
            <motion.div
              className="absolute w-[275px] h-[132.5px] rounded-md border-slate-800/50 bg-[#0b1122] top-[110px]"
              initial={{
                opacity: 1,
              }}
              animate={{
                opacity: 0,
                transition: {
                  delay: 2.6,
                },
              }}
            />
            <motion.div
              className="flex flex-col gap-2"
              initial={{
                y: 0,
              }}
              animate={{
                y: -141.5,
                transition: {
                  delay: 2.6,
                  ease: "easeInOut",
                },
              }}
            >
              <PipelineThread {...threads[3]!} assignee={secondaryAssignee} />
              <PipelineThread {...threads[5]!} assignee={secondaryAssignee} />
              <PipelineThread {...threads[13]!} />
            </motion.div>
          </Container>
          <Container color="#22c55e" status="Closed">
            <PipelineThread {...threads[4]!} assignee={secondaryAssignee} />
            <motion.div
              className="absolute w-[275px] h-[132.5px] rounded-md border-slate-800/50 bg-[#0b1122] top-[108.5px]"
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  delay: 2.6,
                },
              }}
            />
            <motion.div
              initial={{
                y: 0,
              }}
              animate={{
                y: 141.5,
                transition: {
                  delay: 2.6,
                  ease: "easeInOut",
                },
              }}
              className="flex flex-col gap-2"
            >
              <PipelineThread {...threads[8]!} assignee={mainAssignee} />
              <PipelineThread {...threads[14]!} assignee={secondaryAssignee} />
              <PipelineThread {...threads[9]!} />
            </motion.div>
          </Container>
          <Container color="#ef4444" status="Lost">
            <PipelineThread {...threads[10]!} />
          </Container>
        </ColumnsContainer>
        <motion.div
          className="absolute top-0 left-0 size-8 z-[100]"
          initial={{
            x: 200,
            y: 200,
          }}
          animate={{
            x: [524, 524, 524, 832],
            y: [170, 170, 174, 182],
            transition: {
              duration: 1.5,
              delay: 1.8,
              ease: "easeInOut",
              times: [0, 0.1, 0.2, 1],
            },
          }}
        >
          <motion.div
            ref={cursorScope}
            initial={{
              scale: 0,
              opacity: 0,
            }}
          >
            <Cursor />
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};
