"use client";

import { motion } from "framer-motion";
import type React from "react";

interface FlickerWrapperProps {
  off?: boolean;
}

const onSequence = [
  0, 1, 0, 0, 0, 0, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
];
const offSequence = [
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.3, 1, 1, 1, 1,
  0.5, 0.3, 0.5, 0.5, 1, 0.3, 0.3, 0.3, 0.3, 1, 1, 1, 0.3, 0.3, 0.3, 0.3,
];

export const FlickerWrapper: React.FC<
  React.PropsWithChildren<FlickerWrapperProps>
> = ({ children, off = false }) => {
  return (
    <motion.div
      className="relative"
      initial={{ opacity: off ? 1 : 0 }}
      animate={{
        opacity: !off ? onSequence : offSequence,
      }}
    >
      {children}
    </motion.div>
  );
};
