import { MOD } from "@/lib/platform";

import { cn } from "@ui/lib/utils";

export const CommandKbd = ({
  children,
  className,
}: {
  children: string;
  className?: string;
}) => {
  return (
    <kbd
      className={cn(
        "flex h-5 w-auto items-center justify-center rounded-sm border border-slate-700/50 bg-slate-800 p-1 text-xs leading-none tracking-normal text-slate-300",
        className,
      )}
    >
      {children === "⌘"
        ? MOD === "Meta"
          ? children
          : "Ctrl"
        : children === "⌥"
          ? MOD === "Meta"
            ? children
            : "Alt"
          : children}
    </kbd>
  );
};

export const CommandKbdGroup = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={cn("ml-auto flex items-center space-x-[6px]", className)}>
      {children}
    </div>
  );
};
