"use client";

import { TwitterIcon } from "lucide-react";
import { memo } from "react";
import { FlickerWrapper } from "./flicker-wrapper";

interface FlickerLogoProps {
  off?: boolean;
}

const FlickerLogoRaw: React.FC<FlickerLogoProps> = ({ off = false }) => {
  return (
    <FlickerWrapper off={off}>
      <TwitterIcon className="absolute left-0 top-0 h-12 w-12 stroke-blue-300 stroke-[1.75px] blur-xl" />
      <TwitterIcon className="absolute left-0 top-0 h-12 w-12 stroke-blue-200/80 stroke-[1.75px] blur-sm" />
      <TwitterIcon className="h-12 w-12 stroke-[1.75px]" />
    </FlickerWrapper>
  );
};

export const FlickerLogo = memo(FlickerLogoRaw);
