import { ExternalTags } from "@/modules/landing/hero/viewport/sidebar/external-tags";
import Image from "next/image";
import { HeadlessThreadAssignee } from "./headless-thread-assignee";

interface PipelineThreadProps {
  image: string;
  name: string;
  lastMessage: string;
  assignee?: {
    image: string;
    online: boolean;
  };
  tags?: {
    color: string;
    name: string;
  }[];
}

export const PipelineThread = ({
  image,
  name,
  lastMessage,
  assignee,
  tags = [],
}: PipelineThreadProps) => {
  return (
    <div className="relative scroll-m-6 p-3 px-[14px] border-slate-800 bg-slate-900 border-[0.5px] rounded-md ring-0">
      <div className="flex gap-3">
        <div className="w-full overflow-hidden">
          <div className="mb-3 flex gap-3">
            <Image
              className="mt-1.5 ml-1.5 min-w-5 select-none h-5 w-5 flex-shrink-0 rounded-full bg-slate-700/50"
              width={20}
              height={20}
              src={image}
              alt="Avatar"
            />
            <div className="flex flex-col overflow-hidden">
              <p className="overflow-hidden overflow-ellipsis whitespace-nowrap text-sm font-medium">
                {name}
              </p>
              <p className="overflow-hidden overflow-ellipsis whitespace-nowrap text-xs text-slate-400">
                {lastMessage}
              </p>
            </div>
          </div>
        </div>
        <HeadlessThreadAssignee user={assignee} />
      </div>
      <ExternalTags tags={tags} headless />
    </div>
  );
};
