import { DateSeparator } from "./date-separator";
import { Message } from "./message";
import { MessageBar } from "./message-bar";
import { MessageGroup } from "./message-group";

import { BACKGROUND_HSL, gradient } from "@/lib/gradient";

export const ChatContainer = () => {
  return (
    <div className="flex w-full flex-1 flex-col max-w-[600px]">
      <div className="relative h-full justify-end pb-8 pt-3">
        <DateSeparator
          date={new Date().toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
          })}
        />
        <MessageGroup>
          <Message
            content="hey, are you still interested?"
            time="09:32"
            internal
            authorImage="/landing/avatars/ethan.png"
          />
        </MessageGroup>
        <MessageGroup>
          <Message content="I'm considering it" time="09:37" internal={false} />
          <Message
            content="how much would 3 ad creatives go for?"
            time="09:38"
            internal={false}
          />
        </MessageGroup>
        <MessageGroup>
          <Message
            content="we charge $1,600 per minute of production"
            time="09:41"
            internal
            authorImage="/landing/avatars/ethan.png"
          />
          <Message
            content="for 3 creatives ~1 min each that would be $4.8k"
            time="09:41"
            internal
            authorImage="/landing/avatars/ethan.png"
          />
        </MessageGroup>
        <MessageGroup>
          <Message
            content="alright, talked it over with the team"
            time="10:09"
            internal={false}
          />
          <Message
            content="they liked your guys' portfolio for sure"
            time="10:09"
            internal={false}
          />
        </MessageGroup>
        <MessageGroup>
          <Message
            content="happy to do a consultation call"
            time="10:10"
            internal
            authorImage="/landing/avatars/ethan.png"
          />
        </MessageGroup>
        <MessageGroup>
          <Message
            content="yeah sure, let's do that"
            time="10:12"
            internal={false}
          />
          <Message content="30m enough?" time="10:12" internal={false} />
        </MessageGroup>
        <MessageGroup>
          <Message
            content="plenty"
            time="10:13"
            internal
            authorImage="/landing/avatars/ethan.png"
          />
        </MessageGroup>
        <MessageGroup>
          <Message content="works for me!" time="10:13" internal={false} />
        </MessageGroup>
      </div>
      <div className="relative mx-auto w-full max-w-4xl">
        <div className="pointer-events-none absolute bottom-0 left-0 h-[44px] w-full bg-[#080E1E]" />
        <div
          className="pointer-events-none absolute bottom-[43px] left-0 h-[80px] w-full"
          style={{
            background: gradient(BACKGROUND_HSL),
          }}
        />
        <MessageBar />
      </div>
    </div>
  );
};
