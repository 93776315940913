import { CampaignClockInterval } from "./campaign-clock-interval";
import { CampaignNavbar } from "./campaign-navbar";
import { CampaignProperties } from "./campaign-properties";
import { CampaignsList } from "./campaigns-list";
import { MessagesChart } from "./messages-chart";

export const CampaignsView = () => {
  return (
    <div className="flex size-full">
      <CampaignClockInterval />
      <CampaignsList />
      <div className="flex flex-col size-full">
        <CampaignNavbar />
        <section className="px-8 relative flex-1">
          <div className="mx-auto w-full max-w-4xl">
            <CampaignProperties />
            <MessagesChart />
          </div>
        </section>
      </div>
    </div>
  );
};
