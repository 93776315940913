"use client";

import { softSpring } from "@/modules/landing/animations/soft-spring";
import { Button } from "@ui/components/ui/button";
import { cn } from "@ui/lib/utils";
import { motion } from "framer-motion";
import { GripHorizontal, Hexagon } from "lucide-react";

export const Container = ({
  children,
  color,
  status,
  animate = true,
  fullWidth = false,
}: {
  children: React.ReactNode;
  color: string;
  status: string;
  animate?: boolean;
  fullWidth?: boolean;
}) => {
  return (
    <motion.div
      className={cn(
        "flex h-full w-[300px] flex-col gap-4 rounded-md rounded-b-none bg-[#060a1a] pb-0 pt-3",
        fullWidth && "w-[297.3333px] bg-transparent",
      )}
      initial={animate ? { y: 24 } : { y: 0 }}
      animate={
        animate
          ? {
              y: 0,
              transition: softSpring(),
            }
          : { y: 0 }
      }
    >
      <div className="flex items-center justify-between px-3">
        <div className="flex items-center gap-2 text-sm font-medium text-slate-50">
          <Hexagon className="h-4 w-4 stroke-[3px]" style={{ stroke: color }} />
          {status}
        </div>
        <Button size="sm" variant="ghost" className="h-max p-1">
          <GripHorizontal className="h-4 w-4 text-slate-400" />
        </Button>
      </div>
      <div className="flex flex-col gap-2 px-3 relative">{children}</div>
    </motion.div>
  );
};
