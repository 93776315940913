"use client";

import { campaignEventChannel } from "@/events/campaigns";
import { useEvent } from "@/hooks/use-event";
import dayjs from "@tweetdm/lib/dayjs";
import { useCallback, useEffect, useState } from "react";

const calculateDiff = (startedAt: Date, compare: Date, addMs?: number) => {
  const diff =
    dayjs(compare).diff(dayjs(startedAt), "seconds") +
    Math.floor((addMs ?? 0) / 1000);

  return breakIntoComponents(diff);
};

const breakIntoComponents = (seconds: number) => {
  return {
    hours: Math.floor(seconds / 3600),
    minutes: Math.floor((seconds % 3600) / 60),
    seconds: seconds % 60,
  };
};

const formatDiff = (diff: {
  hours: number;
  minutes: number;
  seconds: number;
}) => {
  const ensureZero = (n: number) => n.toString().padStart(2, "0");

  return `${ensureZero(diff.hours)}:${ensureZero(diff.minutes)}:${ensureZero(diff.seconds)}`;
};

export const TimeElapsedComponent = ({
  mountDate,
  existingSecondsElapsed,
}: {
  mountDate: Date;
  existingSecondsElapsed: number;
}) => {
  const getInitial = () => {
    if (existingSecondsElapsed) {
      return formatDiff(breakIntoComponents(existingSecondsElapsed));
    }
    return "00:00:00";
  };

  const [value, setValue] = useState(getInitial());

  const interval = useCallback(() => {
    if (!existingSecondsElapsed) return;

    const diff = calculateDiff(
      mountDate,
      new Date(),
      existingSecondsElapsed * 1000,
    );
    setValue(formatDiff(diff));
  }, [mountDate, existingSecondsElapsed]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setValue(getInitial());
    interval();
  }, [mountDate, existingSecondsElapsed]);

  useEvent(campaignEventChannel, "clockInterval", interval);

  return value;
};
