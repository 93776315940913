"use client";

import { Button } from "@ui/components/ui/button";
import { TextFieldRoot, TextFieldSlot } from "@ui/components/ui/text-field";
import { ListFilterIcon, Plus, Search } from "lucide-react";
import { threads } from ".";
import { Thread } from "./thread";

export const ThreadsList = () => {
  return (
    <div className="border-r-borderLight relative flex h-full w-[230px] min-w-[230px] flex-col border-r-[0.5px]">
      <div className="relative mt-3 px-2">
        <div className="flex items-center gap-2">
          <TextFieldRoot
            placeholder="Search threads..."
            className="z-20 h-8 border-none bg-slate-800/50 text-xs transition-colors duration-300 focus-within:bg-slate-800/75 focus-within:ring-transparent"
            inputClassName="placeholder:text-slate-400/90"
          >
            <TextFieldSlot side="left" className="mr-2">
              <div className="relative h-[14px] w-[14px]">
                <Search className="h-[14px] w-[14px] stroke-slate-500" />
              </div>
            </TextFieldSlot>
          </TextFieldRoot>
          <Button
            size="sm"
            variant="ghost"
            className="h-8 bg-slate-800/50 px-2 text-slate-400"
          >
            <Plus className="h-4 w-4" />
          </Button>
        </div>
        <Button
          variant="ghost"
          size="sm"
          className="mt-1 flex h-6 items-center font-normal text-slate-300"
        >
          <ListFilterIcon className="mr-1.5 h-3 w-3 text-slate-400" />
          Filter
        </Button>
      </div>
      <div className="relative my-3 mt-2">
        {threads.slice(0, 10).map((thread, i) => (
          // biome-ignore lint/suspicious/noArrayIndexKey: won't change
          <Thread key={i} {...thread} highlight={i === 1} />
        ))}
      </div>
    </div>
  );
};
