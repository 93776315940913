"use client";

import {
  CommandKbd,
  CommandKbdGroup,
} from "@/modules/command-menu/command-kbd";
import Image from "next/image";
import Marquee from "react-fast-marquee";

const shortcuts = [
  {
    icon: "check-circle",
    shortcut: "E",
    name: "Mark done",
  },
  {
    icon: "search",
    shortcut: ["⌘", "J"],
    name: "Search threads",
  },
  {
    icon: "bell-dot",
    shortcut: "U",
    name: "Mark unread",
  },
  {
    icon: "user-circle",
    shortcut: ["⌘", "⇧", "M"],
    name: "Switch account",
  },
  {
    icon: "hexagon",
    shortcut: "S",
    name: "Assign a status",
  },
  {
    icon: "arrow-right",
    shortcut: ["G", "then", "C"],
    name: "Go to campaigns",
  },
  {
    icon: "tag",
    shortcut: "T",
    name: "Assign tags",
  },
  {
    icon: "bell-ring",
    shortcut: "H",
    name: "Set a reminder",
  },
];

export const InboxMarquee = () => {
  return (
    <figure className="h-[123px] w-[calc(100%+64px)] -translate-x-8 lg:w-full lg:translate-x-0">
      <figcaption className="sr-only">
        Example actions with their respective keyboard shortcuts. For example,
        mark threads unread with U, assign tags with T, search threads with
        command J, and more.
      </figcaption>
      <div className="mt-12" aria-hidden="true">
        <Marquee gradient gradientColor="#020617">
          {shortcuts.map((shortcut) => (
            <Shortcut key={shortcut.name} {...shortcut} />
          ))}
        </Marquee>
      </div>
      <div className="mt-4" aria-hidden>
        <Marquee gradient gradientColor="#020617" direction="right">
          {shortcuts.reverse().map((shortcut) => (
            <Shortcut key={shortcut.name} {...shortcut} />
          ))}
        </Marquee>
      </div>
    </figure>
  );
};

interface ShortcutProps {
  icon: string;
  shortcut: string | string[];
  name: string;
}

export const Shortcut = ({ icon, name, shortcut }: ShortcutProps) => {
  return (
    <div className="flex items-center gap-[14px] relative overflow-hidden mx-2 w-max bg-gradient-to-r from-slate-900/75 to-slate-900/40 px-4 py-3 rounded-lg">
      <div className="size-4 relative shrink-0">
        <Image
          src={`/landing/icons/${icon}.png`}
          alt={"Image"}
          width={28}
          height={28}
          className="absolute size-[28px] min-w-[28px] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        />
      </div>
      <div className="w-full overflow-hidden flex gap-3">
        <h3 className="text-sm font-medium text-slate-100 whitespace-nowrap overflow-hidden overflow-ellipsis">
          {name}
        </h3>
        <CommandKbdGroup>
          {Array.isArray(shortcut) ? (
            shortcut.map((s) =>
              s !== "then" ? (
                <CommandKbd key={s}>{s}</CommandKbd>
              ) : (
                <span key={s} className="text-xs text-slate-400 mx-[2px]">
                  then
                </span>
              ),
            )
          ) : (
            <CommandKbd>{shortcut}</CommandKbd>
          )}
        </CommandKbdGroup>
      </div>
    </div>
  );
};
