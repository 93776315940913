import { Button } from "@ui/components/ui/button";
import { cn } from "@ui/lib/utils";
import { CircleDashed } from "lucide-react";
import Image from "next/image";

export const HeadlessThreadAssignee = ({
  user,
}: { user?: { image: string; online: boolean } }) => {
  return (
    <Button
      size="sm"
      variant="ghost"
      className="h-max rounded-full text-slate-400 pt-[2px]"
    >
      {user ? (
        <div className="size-4 relative">
          <Image
            alt="avatar"
            src={user.image}
            width={16}
            height={16}
            className="h-4 w-4 rounded-full shrink-0 min-w-4"
          />
          <div
            className={cn(
              "absolute -bottom-[1px] -right-[1px] h-[6px] w-[6px] rounded-full outline outline-[#141D2E] transition-colors",
              user.online ? "bg-green-600" : "bg-slate-700",
            )}
          />
        </div>
      ) : (
        <CircleDashed className="size-4" />
      )}
    </Button>
  );
};
