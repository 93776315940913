import { BACKGROUND_HSL, gradient } from "@/lib/gradient";
import { Button } from "@ui/components/ui/button";
import { Separator } from "@ui/components/ui/separator";
import { motion } from "framer-motion";
import {
  Briefcase,
  ContactRound,
  FlameKindling,
  Landmark,
  type LucideIcon,
  MessagesSquare,
  Plus,
  Snowflake,
  UserCircle,
  Users,
} from "lucide-react";
import { softSpring } from "../../animations/soft-spring";
import { ListIcon } from "./list-icon";

const lists = [
  {
    name: "Ecom Agencies - NYC",
    count: 1287,
    icon: Landmark,
    color: "#22c55e",
  },
  {
    name: "D2C People",
    count: 955,
    icon: Users,
    color: "#60a5fa",
  },
  {
    name: "Winter Season Newsletter",
    count: 1419,
    icon: Snowflake,
    color: "#818cf8",
  },
  {
    name: "Potential Outdoor Partnerships",
    count: 532,
    icon: FlameKindling,
    color: "#f87171",
  },
  {
    name: "Top 1K Accounts in Ecom Space",
    count: 997,
    icon: Briefcase,
    color: "#34d399",
  },
  {
    name: "Mutual",
    count: 1286,
    icon: MessagesSquare,
    color: "#f8fafc",
    isSystem: true,
  },
  {
    name: "Contacted",
    count: 5774,
    icon: UserCircle,
    color: "#f8fafc",
    isSystem: true,
  },
];

export const Lists = ({ animate = false }: { animate?: boolean }) => {
  return (
    <div className="border-r-borderLight relative flex flex-1 w-[230px] min-w-[230px] max-w-[230px] flex-col border-r-[0.5px] pt-4">
      <div className="px-5 w-full flex flex-col">
        <h3 className="text-base font-medium flex items-center gap-2">
          <ContactRound className="h-4 w-4 text-muted-foreground stroke-[2.25px]" />
          Lists
        </h3>
        <div className="text-muted-foreground text-xs mt-[10px]">
          Segment your prospects by creating lists.
        </div>
        <Button
          className="mt-5 flex items-center gap-2"
          variant="outline"
          size="sm"
        >
          <Plus className="w-[14px] h-[14px]" />
          Create a list
        </Button>
      </div>
      <div>
        {lists
          .filter((list) => !list.isSystem)
          .map((list, i) => (
            <List key={list.name} {...list} index={animate ? i : undefined} />
          ))}
      </div>
      <motion.div
        initial={animate ? { opacity: 0, y: 8 } : undefined}
        animate={animate ? { opacity: 1, y: 0 } : undefined}
        transition={softSpring(5, 0.1)}
      >
        <Separator />
      </motion.div>
      <div className="pt-3">
        <motion.div
          className="text-xs font-medium text-slate-400 ml-5"
          initial={animate ? { opacity: 0, y: 8 } : undefined}
          animate={animate ? { opacity: 1, y: 0 } : undefined}
          transition={softSpring(6, 0.1)}
        >
          System lists
        </motion.div>
        <div className="-mt-3">
          {lists
            .filter((list) => list.isSystem)
            .map((list, i) => (
              <List
                key={list.name}
                {...list}
                index={
                  animate
                    ? i + lists.filter((l) => !l.isSystem).length + 2
                    : undefined
                }
              />
            ))}
        </div>
      </div>
    </div>
  );
};

interface ListProps {
  icon: LucideIcon;
  color: string;
  name: string;
  count: number;
  isSystem?: boolean;
  index?: number;
}

const List = ({
  icon: Icon,
  color,
  name,
  count,
  isSystem,
  index,
}: ListProps) => {
  return (
    <motion.div
      className="flex items-center gap-[14px] py-2 mr-2 rounded-r-lg pl-5 relative bg-slate-800/0 transition-[background] hover:bg-slate-800/20 overflow-hidden last-of-type:mb-4 first-of-type:mt-6"
      initial={
        index !== undefined
          ? {
              opacity: 0,
              y: 12,
            }
          : undefined
      }
      animate={
        index !== undefined
          ? {
              opacity: 1,
              y: 0,
              transition: softSpring(index),
            }
          : undefined
      }
    >
      {!isSystem ? (
        <div className="rounded-full shadow-none">
          <ListIcon Icon={Icon} color={color} size={28} />
        </div>
      ) : (
        <div className="w-[28px] h-[28px] shrink-0 flex items-center justify-center relative">
          <div
            className="absolute top-0 left-0 w-full h-full border-[1.5px] border-slate-700 rounded-full"
            style={{
              maskImage: gradient(BACKGROUND_HSL, "right"),
            }}
          />
          <Icon size={14} />
        </div>
      )}
      <div className="pr-3 w-full overflow-hidden pl-1 -ml-1">
        <h3 className="text-sm font-semibold text-slate-100 whitespace-nowrap overflow-hidden overflow-ellipsis">
          {name}
        </h3>
        <p className="text-xs text-slate-400">
          {count.toLocaleString()} profiles
        </p>
      </div>
    </motion.div>
  );
};
