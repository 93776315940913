"use client";

import { cn } from "@ui/lib/utils";
import { useContext } from "react";
import { LeadViewportContext } from "../lead-viewport-context";

export const FilterWrapper = ({ children }: { children: React.ReactNode }) => {
  const { disableBlur } = useContext(LeadViewportContext);

  return (
    <div
      className={cn(
        "flex w-max items-center rounded-lg bg-slate-800/50 text-xs text-slate-200 relative",
        !disableBlur && "backdrop-blur-sm",
      )}
    >
      {children}
    </div>
  );
};
