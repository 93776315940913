import { Button } from "@ui/components/ui/button";
import { cn } from "@ui/lib/utils";
import { motion } from "framer-motion";
import {
  CircleDashed,
  type LucideIcon,
  Megaphone,
  Play,
  Plus,
} from "lucide-react";
import type React from "react";
import { TimeElapsedComponent } from "./time-elapsed-component";

export const CampaignsList = () => {
  return (
    <div className="border-r-borderLight relative flex h-full w-[230px] min-w-[230px] flex-col border-r-[0.5px] py-4">
      <div className="w-full flex flex-col">
        <div className="px-5 flex flex-col">
          <h3 className="text-base font-medium flex items-center gap-2">
            <Megaphone className="h-4 w-4 text-muted-foreground stroke-[2.25px]" />
            Campaigns
          </h3>
          <div className="text-muted-foreground text-xs mt-[10px]">
            Manage outreach at scale with campaigns.
          </div>
          <Button
            className="mt-5 flex items-center gap-2"
            variant="outline"
            size="sm"
          >
            <Plus className="w-[14px] h-[14px]" />
            New campaign
          </Button>
        </div>
        <div className="mt-6">
          <CampaignSection
            icon={Play}
            title="Running"
            color="text-green-100"
            index={0}
          >
            <CampaignItem
              selected
              name="NYC Ecom Outreach"
              status="active"
              timeValue={
                <TimeElapsedComponent
                  mountDate={new Date()}
                  existingSecondsElapsed={45_287}
                />
              }
              index={1}
            />
          </CampaignSection>
          <CampaignSection icon={CircleDashed} title="Paused" index={2}>
            <CampaignItem
              name="Newsletter Announcement"
              status="paused"
              timeValue="00:32:51"
              index={3}
            />
            <CampaignItem
              name="Black Friday Sale Outreach"
              status="paused"
              timeValue="01:02:43"
              index={4}
            />
          </CampaignSection>
          <CampaignSection icon={CircleDashed} title="Drafts" index={5}>
            <CampaignItem
              name="Experimental Script"
              status="draft"
              timeValue="Estimated 6d, 8h"
              index={6}
            />
            <CampaignItem
              name="Sales Outbound V3"
              status="draft"
              timeValue="Estimated 11d, 2h"
              index={7}
            />
            <CampaignItem
              name="Product Launch"
              status="draft"
              timeValue="Estimated 4d, 3h"
              index={8}
            />
          </CampaignSection>
        </div>
      </div>
    </div>
  );
};

interface CampaignSectionProps {
  icon: LucideIcon;
  title: string;
  color?: string;
  children: React.ReactNode;
  index: number;
}

const CampaignSection = ({
  icon: Icon,
  title,
  color = "text-slate-300",
  children,
  index,
}: CampaignSectionProps) => {
  return (
    <>
      <motion.div
        className={cn("flex items-center gap-2 px-5 mt-4", color)}
        initial={{
          opacity: 0,
          y: 8,
        }}
        animate={{
          opacity: 1,
          y: 0,
          transition: {
            delay: 0.1 * index,
            type: "spring",
            damping: 20,
            stiffness: 50,
            mass: 1,
          },
        }}
      >
        <Icon className="w-3 h-3" />
        <div className="text-sm font-medium">{title}</div>
      </motion.div>
      <div className="flex flex-col mt-4">{children}</div>
    </>
  );
};

interface CampaignItemProps {
  selected?: boolean;
  name: string;
  status: "active" | "paused" | "draft" | "completed";
  timeValue: string | React.ReactNode;
  index: number;
}

const CampaignItem = ({
  selected = false,
  name,
  status,
  timeValue,
  index,
}: CampaignItemProps) => {
  return (
    <motion.div
      className="flex items-stretch gap-6 pr-5 py-3 relative"
      initial={{
        opacity: 0,
        y: 12,
      }}
      animate={{
        opacity: 1,
        y: 0,
        transition: {
          delay: 0.1 * index,
          type: "spring",
          damping: 20,
          stiffness: 50,
          mass: 1,
        },
      }}
    >
      <div
        className={cn(
          "absolute top-0 left-0 right-4 h-full rounded-r-lg transition-all",
          selected && "bg-slate-800/60",
        )}
      />
      <div
        className={cn(
          "w-[2px] shrink-0 rounded-full bg-slate-700 relative z-10 opacity-75 transition-all",
          status === "active" && "bg-blue-400",
          selected && "opacity-100",
        )}
      />
      <div className="flex flex-col relative z-10 overflow-hidden pr-4">
        <div className="text-sm font-medium overflow-hidden overflow-ellipsis whitespace-pre">
          {name}
        </div>
        <div className="text-xs text-muted-foreground">{timeValue}</div>
      </div>
    </motion.div>
  );
};
