import { useEffect } from "react";

import type { EventBus, EventMap } from "@/lib/event-bus";

export const useEvent = <T extends EventMap, K extends keyof T>(
  eventChannel: EventBus<T>,
  event: K,
  handler: T[K],
): void => {
  useEffect(() => {
    eventChannel.on(event, handler);
    return () => {
      eventChannel.off(event, handler);
    };
  }, [eventChannel, event, handler]);
};
