import { ChevronRight } from "lucide-react";

export const ExpandableArrow = () => {
  return (
    <div className="size-5 relative text-slate-200 flex items-center">
      <div className="w-[12px] ml-2 h-[2.5px] bg-slate-200 rounded-full opacity-0 group-hover:opacity-100 transition-opacity" />
      <ChevronRight className="absolute top-0 left-0 size-5 text-slate-200 stroke-[3px] transition-transform group-hover:translate-x-2" />
    </div>
  );
};
