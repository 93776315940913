"use client";

import { animate, motion, useMotionValue, useTransform } from "framer-motion";
import { memo, useEffect, useState } from "react";
import { TimeElapsedComponent } from "./time-elapsed-component";

export const CampaignStats = () => {
  const [mountDate, setMountDate] = useState(new Date());
  // const startDate = useMemo(() => {
  //   return mountDate.getTime() - 1000 * 60 * 60 * 24 * 30;
  // }, [mountDate])

  return (
    <div className="grid gap-5 grid-cols-4 w-max">
      <Property label="Scope" unit="users">
        1,769
      </Property>
      <Property label="Time">
        <TimeElapsedComponent
          mountDate={mountDate}
          existingSecondsElapsed={45_287}
        />
        {/* 12:34:47 */}
      </Property>
      <PercentProperty label="Progress" value={0.23} />
      <PercentProperty label="Reply rate" value={0.11} />
    </div>
  );
};

const Property = ({
  label,
  unit,
  children,
}: {
  label: string;
  unit?: string;
  children: React.ReactNode;
}) => {
  return (
    <div className="flex flex-col bg-slate-900 p-3 px-4 rounded-lg border-[0.5px] min-w-[150px]">
      <div className="text-sm text-slate-400">{label}</div>
      <div className="flex gap-1 font-medium">
        <h4 className="text-2xl font-medium">{children}</h4>
        {unit && <div className="text-sm mt-[10px] text-slate-200">{unit}</div>}
      </div>
    </div>
  );
};

const PercentProperty = memo(
  ({
    label,
    unit,
    value,
  }: {
    label: string;
    unit?: string;
    value: number;
  }) => {
    const motionValue = useMotionValue(0);
    const rendered = useTransform(
      () => `${Number(motionValue.get() * 100).toFixed(0)}%`,
    );

    // // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    // useEffect(() => {
    //   motionValue.set(value);
    // }, [value]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
      const controls = animate(motionValue, value, {
        duration: 1,
      });
      return () => controls.stop();
    }, [value]);

    return (
      <Property label={label} unit={unit}>
        <motion.div>{rendered}</motion.div>
      </Property>
    );
  },
);
