import { Button, type ButtonProps } from "@ui/components/ui/button";
import { cn } from "@ui/lib/utils";

export const PropertyButton = ({ className, ...props }: ButtonProps) => {
  return (
    <Button
      className={cn(
        "p-2 px-[10px] rounded-none border-r text-xs focus-visible:ring-0",
        className,
      )}
      variant="ghost"
      size="sm"
      {...props}
    />
  );
};
