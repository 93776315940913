"use client";

import { Button } from "@ui/components/ui/button";
import Link from "next/link";
import { usePostHog } from "posthog-js/react";
import { bookingUrl } from "../common/urls";

export const CtaButtons = ({ authed }: { authed: boolean }) => {
  const posthog = usePostHog();

  return (
    <div className="flex gap-2 items-center">
      {!authed && (
        <Button
          variant="outline"
          asChild
          className="h-7 rounded-lg text-xs outline-none border-none px-3"
          onClick={() => {
            posthog.capture("book_demo_clicked", { location: "navbar" });
          }}
        >
          <Link href={bookingUrl} target="_blank">
            Book a demo
          </Link>
        </Button>
      )}
      <Button
        variant={authed ? "outline" : "default"}
        asChild
        className="text-xs h-7 rounded-lg px-3 border-none"
        onClick={() => {
          if (!authed)
            posthog.capture("primary_cta_clicked", { location: "navbar" });
        }}
      >
        <Link href={authed ? "/" : "/auth/login"}>
          {authed ? "Open app" : "Sign up"}
        </Link>
      </Button>
    </div>
  );
};
